import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getSchools, createSchool } from '../api';
import { useNotification } from './useNotification';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants';

export const useSchools = () => {
  const [schools, setSchools] = useState<any>([]);
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useNotification();
  const { data, isLoading } = useQuery(['schools'], getSchools);
  const queryClient = useQueryClient();

  const mutationAdd = useMutation(createSchool, {
    onSuccess: () => {
      notifySuccess('School created successfuly!');
      navigate(ROUTES.SCHOOLS);
      queryClient.invalidateQueries(['schools']);
    },
    onError: (error: any) => {
      notifyError(error.message);
    },
  });

  useEffect(() => {
    if (data) {
      setSchools(data.data.data);
    }
  }, [data]);

  return {
    schools,
    isLoading,
    mutationAdd,
  };
};
