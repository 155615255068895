import { Box, FormControl, Input, InputLabel, Select, styled } from '@mui/material';
import { CustomText } from '../../common';

export interface WrapperTypes {
  info?: string;
  id?: string;
  alignitems?: string;
  width?: string;
  children?: any;
  fullHeight?: boolean;
  labelColor?: string;
}

export const InputContainer = styled(Box)<{
  width?: string;
  alignitems?: string;
  fullHeight?: boolean;
}>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-items: ${({ alignitems }) => alignitems || 'center'};
  justify-content: center;
  flex-direction: column;
  padding: 5px 0;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'fit-content')};

  & .Mui-focused {
    outline-color: blue;
  }
`;

export const LabelInput = styled(InputLabel)<{
  labelColor?: string;
}>`
  align-self: flex-start;
  color: ${({ labelColor, theme }) => (labelColor ? labelColor : theme.palette.common.black)};
`;

export const InputWrapper: React.FC<WrapperTypes> = ({
  width,
  alignitems,
  id,
  info,
  children,
  fullHeight,
  labelColor,
}) => (
  <InputContainer width={width} alignitems={alignitems} fullHeight={fullHeight}>
    <LabelInput htmlFor={id} labelColor={labelColor}>
      <CustomText color={labelColor} fontSize={1.2} bold={700}>
        {info}
      </CustomText>
    </LabelInput>
    {children}
  </InputContainer>
);
