import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { MuiIconContainer } from './AdminConsole.style';
import { DashboardCircleProps } from '../../common/index';
import { ROUTES } from '../../constants';
import { Avatar } from '@mui/material';
import { Headset } from '../../svg/Headset';
import { School } from '../../svg/School';
import { Overview } from '../../svg/Overview';
import { Notation } from '../../svg/Notation';
import { Mail } from '../../svg/Mail';

export interface Links extends DashboardCircleProps {
  link: string;
  smImage?: string;
  noDesc?: boolean;
  btnOption?: string;
  dontShow?: boolean;
}

export const links: Links[] = [
  {
    image: <Overview />,
    banner: 'Overview',
    descriptionText: 'View meaningful data for effective decision making',
    link: ROUTES.OVERVIEW,
    smImage: '/user-sm.png',
  },
  {
    image: <School />,
    banner: 'Schools',
    descriptionText: 'Manage information about our schools',
    link: ROUTES.SCHOOLS,
    smImage: '/user-sm.png',
    btnOption: 'Add New',
  },
  {
    image: <School />,
    banner: 'School',
    descriptionText: 'Manage information about our schools',
    link: ROUTES.SCHOOLPROFILE,
    smImage: '/user-sm.png',
    btnOption: 'Edit',
    dontShow: true,
  },
  {
    image: <School />,
    banner: 'School',
    descriptionText: 'Manage information about our schools',
    link: ROUTES.ADDSCHOOL,
    smImage: '/user-sm.png',
    dontShow: true,
  },
  {
    image: <Headset />,
    banner: 'External Support',
    descriptionText: 'Create and view external support tickets',
    link: ROUTES.EXTERNAL_SUPPORT,
    smImage: '/user-sm.png',
    btnOption: 'Compose',
  },

  {
    image: <Headset />,
    banner: 'External Support',
    descriptionText: 'Create and view external support tickets',
    link: ROUTES.EXTERNAL_SUPPORT_TICKET,
    smImage: '/user-sm.png',
    dontShow: true,
  },
  {
    image: <Notation />,
    banner: 'Invoices',
    descriptionText: 'View financial records of services',
    link: ROUTES.INVOICES,
    smImage: '/user-sm.png',
    btnOption: 'Add Invoice',
  },
  {
    image: <Mail />,
    banner: 'Mail',
    descriptionText: 'Connect instantly with your organisation',
    link: ROUTES.MAIL,
    smImage: '/user-sm.png',
    btnOption: 'Compose',
  },
  // {
  //   image: (
  //     <MuiIconContainer>
  //       <AdminPanelSettingsIcon style={{ fill: '#0C84FC' }} />
  //     </MuiIconContainer>
  //   ),
  //   banner: 'Permissions',
  //   descriptionText: 'Manage administrative roles',
  //   link: ROUTES.PERMISIONS,
  //   smImage: '/user-sm.png',
  //   btnOption: 'Add User',
  // },
];
