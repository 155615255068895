import { Star, StarBorder } from '@mui/icons-material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setMailItem } from '../../store/slice';
import { ROUTES } from '../../constants';
import { MailListItem, MailListItemContent, Marker, MuiIcon } from './Mail.style';
import moment from 'moment';
import { Flex } from '../../common/Containers';
import { CircularProgress, Skeleton } from '@mui/material';
import { favoriteMail } from '../../api';
import { useNotification } from '../../hooks/useNotification';
import { CustomText } from '../../common';

interface MailItemProps {
  user: any;
  subject: string;
  date: number;
  unread: boolean;
  isFavorite: boolean;
  sentType: string;
  id: string;
  body: string;
  type: string;
  invoiceId?: string;
}

export const MailItem: React.FC<MailItemProps> = ({
  user,
  subject,
  date,
  unread,
  isFavorite,
  sentType,
  id,
  body,
  type,
  invoiceId,
}) => {
  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [fav, setFav] = useState(!!isFavorite);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bold = unread ? 700 : 300;
  const { notifySuccess } = useNotification();

  const setMailFavorite = async () => {
    setFavoriteLoading(true);
    const res = await favoriteMail(id, !fav);
    if (res.data.message) {
      notifySuccess(res.data.message);
    }
    setFav((prevState) => !prevState);
    setFavoriteLoading(false);
  };

  return (
    <MailListItem cursor="pointer">
      {unread && <Marker bgColor />}
      <Flex flex={15}>
        <Flex>
          {!favoriteLoading ? (
            <MuiIcon onClick={setMailFavorite}>{fav ? <Star /> : <StarBorder />}</MuiIcon>
          ) : (
            <CircularProgress size={20} />
          )}
          <CustomText
            bold={bold}
            onClick={() => {
              dispatch(
                setMailItem({
                  user,
                  subject,
                  date,
                  isFavorite,
                  sentType,
                  id,
                  body,
                  type,
                  invoiceId,
                }),
              );
              navigate(`${ROUTES.MAIL_PAGE}/${id}`);
            }}
          >
            {user.name}
          </CustomText>
        </Flex>
      </Flex>
      <Flex flex={20}>
        <CustomText bold={bold}>{subject}</CustomText>
      </Flex>
      <Flex flex={10}>
        <CustomText bold={bold}>{moment.unix(date).format('MM/DD/YYYY')}</CustomText>
      </Flex>
      <Flex flex={10}>
        <CustomText bold={bold}>{moment.unix(date).format('HH:MM')}</CustomText>
      </Flex>
    </MailListItem>
  );
};

export const MailItemSkeleton = () => {
  return (
    <MailListItem cursor="pointer">
      <Flex flex={15}>
        <Flex>
          <Skeleton height={24} width={24} variant="circular" />
          <Skeleton height="1.2rem" width={300} />
        </Flex>
      </Flex>
      <Flex flex={20}>
        <Skeleton height="1.2rem" width={200} />
      </Flex>
      <Flex flex={10}>
        <Skeleton height="1.2rem" width={70} />
      </Flex>
      <Flex flex={10}>
        <Skeleton height="1.2rem" width={50} />
      </Flex>
    </MailListItem>
  );
};
