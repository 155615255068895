import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { CustomButton, Flex, MessagesAndFormContainer } from '../../common';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../constants';
import { useInvoice } from '../../hooks/useInvoice';
import { useAppSelector } from '../../store';
import { ExternalSupportTicketDetail } from '../ExternalSupportTicket/ExternalSupportTicketDetail';
import { InvoiceDetailModal } from './InvoiceDetailModal';
import { MailMessage } from './MailMessage';

export const MailPage = () => {
  const navigate = useNavigate();
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const { mailItem } = useAppSelector((state) => state.user);
  const { invoice } = useInvoice(mailItem.invoiceId);

  if (!mailItem) return <Navigate to={ROUTES.MAIL} />;

  return (
    <>
      <RouteInfo banner="Mail" description="Connect instantly with your organisation" />
      <Flex column>
        <ExternalSupportTicketDetail onClick={() => navigate(ROUTES.MAIL)}>
          {mailItem.type === 'invoice' && (
            <CustomButton content="Invoice Details" onClick={() => setInvoiceModalOpen(true)} />
          )}
        </ExternalSupportTicketDetail>
        <MessagesAndFormContainer>
          <MailMessage {...mailItem} />
        </MessagesAndFormContainer>
      </Flex>
      <InvoiceDetailModal
        open={invoiceModalOpen}
        {...invoice}
        handleClose={() => setInvoiceModalOpen(false)}
      />
    </>
  );
};
