import React, { useEffect, useState } from 'react';
import { OverviewItem } from '../../pages/Overview/Overview.style';
import {
  LineChart,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';
import { ChartTitle } from '../../common/ChartTitle';
import { CircularProgress, useTheme } from '@mui/material';
import { getAnalytics } from '../../api';
import moment from 'moment';
import { CustomText, Flex } from '../../common';
import { thousandToK } from '../../helpers';
import { SmallFillter } from '../Filters/SmallFilter';

export const EarningAnalytics = () => {
  const theme = useTheme();
  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    from: moment().startOf('year').unix(),
    to: moment().startOf('second').unix(),
  });
  const [value, setValue] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  const onSave = (value: any) => {
    setFilter((prev) => ({
      ...prev,
      ...value,
    }));
    setFilterOpen(false);
  };
  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('overview/earnings', filter);
    console.log(data);
    setValue(data?.data.data.monthsData);
    setTotalValue(data?.data.data.total);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [filter]);
  return (
    <OverviewItem>
      <ChartTitle
        title="Earning Analytics"
        onClick={() => setFilterOpen(true)}
        filter={{
          from: moment.unix(filter.from).format('MMM/DD/YYYY'),
          to: moment.unix(filter.to).format('MMM/DD/YYYY'),
        }}
      />
      <div style={{ width: '100%', height: '100%', flex: 1, padding: '40px 20px 20px 0px' }}>
        {loading ? (
          <Flex fullHeight contectCenter>
            <CircularProgress size="4rem" />
            <CustomText fontSize={1.4} color={theme.color.darkerBlue}>
              Loading...
            </CustomText>
          </Flex>
        ) : (
          <ResponsiveContainer height="100%" width="100%">
            <LineChart data={value}>
              <CartesianGrid vertical={false} />
              <Tooltip />
              <YAxis
                axisLine={false}
                tickMargin={5}
                dataKey="total"
                padding={{ bottom: 20 }}
                tickFormatter={(value) => `${'£'}${thousandToK(value / 100)}`}
              />
              <XAxis
                dataKey="monthStartDate"
                tickLine={false}
                tickMargin={10}
                padding={{ left: 20 }}
                tickFormatter={(value) => `${moment.unix(value).format('MMM')}`}
              />
              <Line
                type="linear"
                dataKey="total"
                stroke="#0C84FC"
                dot={{ r: 6, fill: '#0C84FC' }}
                activeDot={false}
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
      <SmallFillter open={filterOpen} onSave={onSave} handleClose={() => setFilterOpen(false)} />
    </OverviewItem>
  );
};
