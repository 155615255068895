import { Divider, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Flex, WhiteContainer, CustomButton, CustomText } from '../../../common';
import { InputField } from '../../../components/Inputs';
import { AddInvoiceItem, AddItemButton } from './AddInvoiceItem';
import { ItemContainer } from './AddInvoice.style';

export const Right: React.FC<{
  values: any;
  forceChange: any;
  onChange: any;
  handleSubmit: any;
  loading: boolean;
}> = ({ values, onChange, forceChange, handleSubmit, loading }) => {
  const theme = useTheme();
  const [items, setItems]: any[] = useState([{ name: '', quantity: null, price: null }]);
  const [total, setTotal] = useState(0);

  const addItem = () =>
    setItems((prev: any[]) => [...prev, { name: '', quantity: null, price: null }]);
  const handleItemChange = (key: string, index: number, value: any) => {
    setItems((prev: any[]) => {
      prev[index][key] = value;
      return [...prev];
    });
  };

  useEffect(() => {
    forceChange(items, 'items');
  }, [items]);

  useEffect(() => {
    let totalPrice = 0;
    items.map((item: any) => {
      totalPrice += item.price * item.quantity;
    });
    setTotal(totalPrice + (totalPrice * values.vatPercentage) / 100);
  }, [items, values.vatPercentage]);

  return (
    <Flex flex={2} fullHeight column p="0 0 20px 0">
      <ItemContainer>
        {items?.map((item: any, index: number) => (
          <AddInvoiceItem index={index} values={item} key={index} onChange={handleItemChange} />
        ))}
        <Flex noFlex flexEnd>
          <AddItemButton onClick={addItem} />
        </Flex>
      </ItemContainer>
      <Flex column>
        <Flex column noFlex>
          <InputField
            type="text"
            name="description"
            info="Description"
            padding="4px 20px"
            placeholder="Enter here..."
            fullHeight
            multiline
            rows={12}
            onChange={onChange}
            value={values.description}
          />
          <Divider orientation="horizontal" flexItem />
          <Flex>
            <Flex>
              <InputField
                type="number"
                name="vatPercentage"
                info="VAT"
                padding="4px 20px"
                width="30%"
                onChange={onChange}
                value={values.vatPercentage}
              />
              <InputField
                type="number"
                name="name"
                width="50%"
                info="Total Amount"
                padding="4px 20px"
                placeholder="£00000"
                labelColor={theme.color.darkerBlue}
                disabled
                value={total / 100}
              />
            </Flex>
            <Flex fullHeight flexEnd column>
              <Flex column flexEnd noFlex>
                <Flex gap={30} flexEnd>
                  <Flex noFlex>
                    <CustomButton width="200px" bgcolor={theme.color.grey}>
                      <CustomText>Cancel</CustomText>
                    </CustomButton>
                  </Flex>
                  <Flex noFlex>
                    <CustomButton width="200px" onClick={handleSubmit} loading={loading}>
                      <CustomText color="white">Save</CustomText>
                    </CustomButton>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
