import { Box, styled } from '@mui/material';
import { WhiteContainer } from '../../common';

export const OverviewContainer = styled(Box)`
  flex: 1;
  padding: 0px 20px 20px 20px;
  display: grid;
  grid-template-rows: 3fr 2fr;
  gap: 30px;
`;

export const InnerOverviewContainer = styled(Box)<{
  spreadItems: string;
}>`
  display: grid;
  grid-template-columns: ${({ spreadItems }) => spreadItems};
  gap: 30px;
`;

export const OverviewItem = styled(WhiteContainer)`
  width: 100%;
  height: 100%;
  padding: 0;
  align-items: flex-start;
  flex-direction: column;
`;
