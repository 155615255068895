import React from 'react';
import moment from 'moment';
import { Flex, WhiteContainer } from '../../../common';
import { InfoTitle } from '../../../components/InfoTitle';
import { DateInput, SelectField } from '../../../components/Inputs';
import { InfoItem } from '../../../components/SchoolProfile/UserCountCard';
import { SchoolSelect } from '../../../components/Inputs/SchoolSelect';

export const Left: React.FC<{ values: any; forceChange: any; onChange: any }> = ({
  values,
  onChange,
  forceChange,
}) => {
  return (
    <Flex column fullHeight>
      <WhiteContainer padding="0px" noFlex>
        <Flex column fullHeight>
          <InfoTitle title="Invoice Details" />
          <Flex column p="20px 30" noFlex>
            <DateInput
              value={moment.unix(values.date)}
              onChange={(e: any) => {
                forceChange(moment(e).unix(), 'date');
              }}
              name="date"
              id="date"
              info="Date"
            />
            <SchoolSelect
              onChange={(e: any, obj: any) => forceChange(obj.id, 'schoolId')}
              value={values.firstName}
            />
          </Flex>
        </Flex>
      </WhiteContainer>
      <WhiteContainer padding="0px" noFlex>
        <Flex column fullHeight>
          <InfoTitle title="PW Details" />
          <Flex column p="20px 30">
            <InfoItem title="Company Name" content="Pupil Wallet" />
            <InfoItem title="Address" content="46 Blackheath Park, London, UK" />
            <InfoItem title="Email" content="office@pupilwallet.com" />
          </Flex>
        </Flex>
      </WhiteContainer>
    </Flex>
  );
};
