import React, { useEffect, useState } from 'react';
import { OverviewContainer, OverviewItem, InnerOverviewContainer } from './Overview.style';
import { CustomText, Flex, InfoMain } from '../../common';
import { Earnings, EarningAnalytics } from '../../components/OverviewCharts';
import { InfoTitle } from '../../common';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { School } from '../../svg/School';
import { Mail } from '../../svg/Mail';
import { Headset } from '../../svg/Headset';
import { Notation } from '../../svg/Notation';
import { Skeleton, useTheme } from '@mui/material';
import { getAnalytics } from '../../api';
import CountUp from 'react-countup';
import { ROUTES } from '../../constants';

export const Overview = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState({
    externalSupportTicketsData: {
      resolvedCount: 0,
      totalCount: 0,
      unResolvedCount: 0,
    },
    invoiceData: {
      latestInvoice: {
        totalItemsPrice: 0,
        school: {
          name: null,
        },
      },
      totalCount: 0,
    },
    mailsData: {
      latestMail: {
        from: {
          name: null,
        },
        subject: '',
      },
      mailsCount: 0,
    },
    schoolData: {
      parentsCount: 0,
      schoolsCount: 0,
      staffCount: 0,
      studentsCount: 0,
    },
    totalEarnings: 0,
  });

  const getData = async () => {
    setLoading(true);
    const data = await getAnalytics('overview');
    console.log(data);
    setValue(data?.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <RouteInfo />
      <OverviewContainer>
        <InnerOverviewContainer spreadItems="3fr 7fr">
          <Earnings value={value.totalEarnings} />
          <EarningAnalytics />
        </InnerOverviewContainer>
        <InnerOverviewContainer spreadItems="1fr 1fr 1fr 1fr">
          <OverviewItem>
            <InfoTitle title="SCHOOLS" image={<School color="white" />} link={ROUTES.SCHOOLS} />
            <InfoMain title="Total number of schools" value={value.schoolData.schoolsCount}>
              <CustomText>
                Total number of students: <CountUp end={value.schoolData.studentsCount} />
              </CustomText>
              <CustomText>
                Total number of parents: <CountUp end={value.schoolData.parentsCount} />
              </CustomText>
              <CustomText>
                Total number of staff: <CountUp end={value.schoolData.staffCount} />
              </CustomText>
            </InfoMain>
          </OverviewItem>
          <OverviewItem>
            <InfoTitle title="INVOICES" image={<Notation color="white" />} link={ROUTES.INVOICES} />
            <InfoMain title="Total Invoices" value={value.invoiceData.totalCount}>
              <CustomText bold={700}>LATEST INVOICE</CustomText>
              <Flex noFlex>
                <CustomText bold={700}>For: </CustomText>{' '}
                <CustomText>
                  {value.invoiceData?.latestInvoice?.school?.name || (
                    <Skeleton width={100} height={20} />
                  )}
                </CustomText>
              </Flex>
              <Flex noFlex>
                <CustomText bold={700}>Amount: </CustomText>{' '}
                <CustomText>
                  £<CountUp end={value.invoiceData?.latestInvoice?.totalItemsPrice / 100} />
                </CustomText>
              </Flex>
            </InfoMain>
          </OverviewItem>
          <OverviewItem>
            <InfoTitle title="MAIL" image={<Mail color="white" />} link={ROUTES.MAIL} />
            <InfoMain title="Total Messages" value={value.mailsData.mailsCount}>
              <CustomText bold={700}>LATEST MESSAGE</CustomText>
              <Flex noFlex>
                <CustomText bold={700}>From: </CustomText>{' '}
                <CustomText>
                  {value.mailsData.latestMail.from.name || <Skeleton width={150} height={20} />}
                </CustomText>
              </Flex>
              <Flex noFlex>
                <CustomText bold={700}>Subject: </CustomText>{' '}
                <CustomText>
                  {value.mailsData.latestMail.subject || <Skeleton width={200} height={20} />}
                </CustomText>
              </Flex>
            </InfoMain>
          </OverviewItem>
          <OverviewItem>
            <InfoTitle
              title="EXTERNAL SUPPORT"
              image={<Headset color="white" />}
              link={ROUTES.EXTERNAL_SUPPORT}
            />
            <InfoMain title="Total Issues" value={value.externalSupportTicketsData.totalCount}>
              <CustomText>
                Resolved: <CountUp end={value.externalSupportTicketsData.resolvedCount} />
              </CustomText>
              <CustomText>
                Unresolved: <CountUp end={value.externalSupportTicketsData.unResolvedCount} />
              </CustomText>
            </InfoMain>
          </OverviewItem>
        </InnerOverviewContainer>
      </OverviewContainer>
    </>
  );
};
