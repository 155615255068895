import { styled, Box } from '@mui/material';
import React from 'react';
import { Footer } from '../svg/Footer';

export const ImageContainer = styled(Box)`
  width: 100%;
  justify-self: flex-end;
  left: 0;
  margin-bottom: -10px;
  z-index: 10;
  padding: 0;

  svg: {
    height: 20px;
  }
  // @media(max-width: 1444px) {
  //   z-index: 4;
  // }
`;

export const FooterShape = () => (
  <ImageContainer>
    <Footer />
  </ImageContainer>
);
