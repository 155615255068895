import { styled, Box } from '@mui/material';

export const ElementaryDataContainer = styled(Box)<{
  width?: string;
  alignItems?: string;
  justifyContent?: string;
  gap?: string;
  column?: boolean;
  margin?: string;
}>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  flex: 1;
  gap: ${({ gap }) => (gap ? `${gap}px` : '0')};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  margin: ${({ margin }) => margin || '0'};

  @media (max-width: 768px) {
    width: 90%;
    padding: 20px 0;

    button {
      flex: 0 0 44%;
    }
  }
`;

export const ReplyContainer = styled(Box)`
  height: 50vh;
  flex: 0 0 45%;
  padding: 35px 0 0 44px;
  position: relative;

  @media (max-width: 768px) {
    padding: 0;
    margin: 2% 0;
  }
`;

export const DetailsContainer = styled(Box)<{ height?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const PersonInfoContainer = styled(Box)`
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;
`;

export const PersonInfoContent = styled(Box)<{ gap?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ gap }) => (gap ? `${gap}px` : 0)};
`;
