import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

import { CustomText, Flex, ElementaryDataContainer } from '../../common';

interface ExternalSupportTicketDetailProps {
  onClick: () => void;
  children?: any;
}

export const ExternalSupportTicketDetail: React.FC<ExternalSupportTicketDetailProps> = ({
  onClick,
  children,
}) => (
  <Flex>
    <ElementaryDataContainer>
      <IconButton onClick={onClick}>
        <ArrowBack />
      </IconButton>
      <CustomText fontSize={1.2} bold={700}>
        Message Details
      </CustomText>
    </ElementaryDataContainer>
    <Flex p="0 20" flexEnd>
      {children}
    </Flex>
  </Flex>
);
