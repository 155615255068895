import { styled, Box } from '@mui/material';

export const IconContainer = styled(Box)<{
  p?: string;
  size?: string;
}>`
  padding: ${({ p }) => p || '25px'};
  background-color: ${({ theme }) => theme.color.darkerBlue};
  border-radius: 50%;
  width: ${({ size }) => size || '24px'};
  height: ${({ size }) => size || '24px'};
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
