import { Typography, styled, Box } from '@mui/material';

export const CustomText = styled(Typography)<{
  fontSize?: number;
  color?: string;
  bold?: number;
  margin?: string;
}>`
  font-size: ${({ fontSize }) => `${fontSize}rem` || '1rem'};
  font-weight: ${({ bold }) => bold || 400};
  color: ${({ theme, color }) => color || theme.palette.common.black};
  margin: ${({ margin }) => margin || '0'};
`;

export const TextContainer = styled(Box)<{
  center?: boolean
}>`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
`
