import { Box, styled } from '@mui/material';

export const MessagesList = styled(Box)`
  height: 100%;
  width: 100%;
`;

export const MessagesContainer = styled(Box)<{ bottom?: string }>`
  width: 100%;
  padding: 16px 0;
  border-bottom: ${({ bottom, theme }) => bottom && `1px solid ${theme.color.borderColor}`};
`;

export const MessagesAndFormContainer = styled(Box)`
  height: 90%;
  width: 98%;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
