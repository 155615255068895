import { Box, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { WhiteContainer } from '../../common';
import { InitialCircle } from '../../common/InitialCircle';

export const CardContainer = styled(Box)`
  width: 100%;
  aspect-ratio: 1/1.4;
  min-width: 15rem;
  display: flex;
  flex-direction: column;
`;

export const CardContent = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const CardBottom = styled(Box)<{ samePadding?: boolean }>`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.borderGrey};
  padding: ${({ samePadding }) => (samePadding ? '5px' : '5px 5px 5px 20px')};
  display: flex;
  align-items: center;
`;

export const CardImageContainer = styled(Box)<{
  size?: number;
  color?: string;
}>`
  min-width: ${({ size }) => (size ? size : 70)}%;
  aspect-ratio: 1/1 !important;
  border-radius: 50%;
  background-color: aliceblue;
  border: 3px solid ${({ theme, color }) => (color ? '#' + color : theme.color.darkerBlue)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const Image = styled(WhiteContainer)`
  max-width: 90%;
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
`;

const ImageCard = styled(WhiteContainer)`
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
`;

export const CardImage: React.FC<{ onClick?: any; size?: number; image?: any; color?: string }> = ({
  size,
  image,
  color,
  onClick,
}) => {
  return (
    <>
      <CardImageContainer size={size} color={color} onClick={onClick}>
        <ImageCard>{typeof image === 'string' ? <InitialCircle img={image} /> : image}</ImageCard>
      </CardImageContainer>
    </>
  );
};
