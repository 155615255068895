import { ROUTES } from './constants';
import { RouteObject, useRoutes, Navigate } from 'react-router-dom';
import { USER } from './config/axios';

import {
  Login,
  Dashboard,
  AdminConsole,
  Overview,
  Schools,
  SchoolProfile,
  MailPage,
  Mail,
  ExternalSupport,
  ExternalSupportTicket,
  Invoices,
  AddInvoice,
  InvoiceDetails,
  AddSchool,
} from './pages';
import { OneSchoolInvoices } from './pages/Invoices/OneSchoolInvoices';

const getRoutes = (isLogged: boolean): RouteObject[] => [
  {
    path: ROUTES.LOGIN,
    element: isLogged ? <Navigate to={ROUTES.DASHBOARD} /> : <Login />,
  },
  {
    path: ROUTES.DASHBOARD,
    element: isLogged ? <Dashboard /> : <Navigate to={ROUTES.LOGIN} />,
    // element: <Dashboard />,
    children: [
      {
        path: ROUTES.ADMIN,
        element: <AdminConsole />,
      },
      {
        path: ROUTES.OVERVIEW,
        element: <Overview />,
      },
      {
        path: ROUTES.SCHOOLS,
        element: <Schools />,
      },

      {
        path: `${ROUTES.SCHOOLPROFILE}/:id`,
        element: <SchoolProfile />,
      },
      {
        path: `${ROUTES.SCHOOLPROFILE}/:id/invoices`,
        element: <OneSchoolInvoices />,
      },
      {
        path: `${ROUTES.ADDSCHOOL}`,
        element: <AddSchool />,
      },
      {
        path: ROUTES.EXTERNAL_SUPPORT,
        element: <ExternalSupport />,
      },
      {
        path: `${ROUTES.EXTERNAL_SUPPORT_TICKET}/:id`,
        element: <ExternalSupportTicket />,
      },
      {
        path: ROUTES.MAIL,
        element: <Mail />,
      },
      {
        path: `${ROUTES.MAIL_PAGE}/:id`,
        element: <MailPage />,
      },
      {
        path: ROUTES.INVOICES,
        element: <Invoices />,
      },
      {
        path: ROUTES.INVOICEDETAILS,
        element: <InvoiceDetails />,
      },
    ],
  },
];

export const AppRoutes = () => {
  console.log(localStorage.getItem(USER));
  const routes = useRoutes(getRoutes(!!localStorage.getItem(USER)));

  return <>{routes}</>;
};
