import React from 'react';
import { useSchools } from '../../hooks/useSchools';
import { AutocompleteInput } from './AutocompleteInput';

export const SchoolSelect: React.FC<{ value: any; onChange: any; info?: string }> = ({
  value,
  onChange,
  info,
}) => {
  const { schools } = useSchools();
  return (
    <AutocompleteInput
      value={value}
      name="school"
      info={info || 'School Name'}
      padding="4px 20px"
      placeholder="Select"
      options={schools}
      onChange={onChange}
    />
  );
};
