import { Stack, useTheme } from '@mui/material';
import React from 'react';

import { useAuth } from '../../hooks/useAuth';
import { CustomButton, CustomText } from '../../common';
import { LogoutContent, LogoutModal } from './Logout.style';

interface LogoutProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Logout: React.FC<LogoutProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const { logout } = useAuth();

  return (
    <LogoutModal open={isOpen} onClose={onClose}>
      <LogoutContent>
        <CustomText fontSize={1.4} bold={700} color={theme.color.lightBlue} margin="0 0 20px 0">
          LOGOUT
        </CustomText>

        <Stack
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          padding="44px 44px 0 44px"
        >
          <CustomText>Are you sure to want to logout?</CustomText>
          <Stack direction="row" spacing={2}>
            <CustomButton width="150px" bgcolor={theme.palette.grey[300]} onClick={onClose}>
              No
            </CustomButton>
            <CustomButton width="150px" onClick={logout}>
              Yes
            </CustomButton>
          </Stack>
        </Stack>
      </LogoutContent>
    </LogoutModal>
  );
};
