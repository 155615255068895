import { InputFieldProps, CustomInput } from './Input';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { InputWrapper } from './InputWrapper';

export const DateInput: React.FC<InputFieldProps> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  value,
  onChange,
  minDate,
  ...rest
}) => (
  <InputWrapper width={width} alignitems={alignitems} info={info}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={onChange}
        minDate={minDate}
        renderInput={(params) => (
          <CustomInput
            {...params}
            style={{ textTransform: 'uppercase' }}
            placeholder="DD/MM/YYY"
            error={false}
            {...rest}
          />
        )}
      />
    </LocalizationProvider>
  </InputWrapper>
);

export const YearInput: React.FC<InputFieldProps> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  value,
  onChange,
  ...rest
}) => (
  <InputWrapper width={width} alignitems={alignitems} info={info}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={onChange}
        views={['year']}
        renderInput={(params) => (
          <CustomInput {...params} placeholder="YYYY" error={false} {...rest} />
        )}
      />
    </LocalizationProvider>
  </InputWrapper>
);

export const MonthInput: React.FC<InputFieldProps> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  value,
  onChange,
  ...rest
}) => (
  <InputWrapper width={width} alignitems={alignitems} info={info}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={onChange}
        views={['month']}
        inputFormat="MMMM"
        renderInput={(params) => (
          <CustomInput {...params} placeholder="MMM" error={false} {...rest} />
        )}
      />
    </LocalizationProvider>
  </InputWrapper>
);
