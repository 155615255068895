import React from 'react';

export const Mail: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="58" height="54" viewBox="0 0 58 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M57.3051 19.6667C57.3051 17.6267 56.2567 15.8417 54.6418 14.85L31.8617 1.505C30.1051 0.456667 27.8951 0.456667 26.1384 1.505L3.35841 14.85C1.74341 15.8417 0.666748 17.6267 0.666748 19.6667V48C0.666748 51.1167 3.21675 53.6667 6.33342 53.6667H51.6668C54.7834 53.6667 57.3334 51.1167 57.3334 48L57.3051 19.6667ZM25.9967 31.9633L5.59675 19.2133L26.1384 7.17167C27.8951 6.12333 30.1051 6.12333 31.8617 7.17167L52.4034 19.2133L32.0034 31.9633C30.1618 33.0967 27.8384 33.0967 25.9967 31.9633Z"
      fill={color ? color : '#0C84FC'}
    />
  </svg>
);
