import React from "react";

export const NavArrow = () => (
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.86687 0.646555C9.68938 0.451322 9.40156 0.451289 9.22405 0.646589L5.00011 5.29302L0.775955 0.646555C0.59847 0.451322 0.310652 0.451289 0.133136 0.646589C-0.0443788 0.841855 -0.0443788 1.15842 0.133136 1.35369L4.67871 6.35369C4.76395 6.44746 4.87956 6.50012 5.00011 6.50012C5.12065 6.50012 5.23629 6.44742 5.3215 6.35366L9.86683 1.35366C10.0444 1.15842 10.0444 0.841822 9.86687 0.646555Z"
      fill="white"
    />
  </svg>
);
