import { useState } from 'react';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { Flex, WhiteContainer } from '../../common/Containers';

import { useTheme } from '@mui/material';
import {
  UserCountCard,
  LastUserCountCard,
  Buttons,
  InfoItem,
} from '../../components/SchoolProfile/UserCountCard';
import { InfoTitle } from '../../components/InfoTitle';
import { useSchoolProfile } from '../../hooks/useSchoolProfile';
import { SchoolInfoCardContent, SchoolInfoContainer } from './Schools.style';
import { CardImage } from '../../components/Card/Card.style';
import { CustomButton, CustomText } from '../../common';
import { useLocation, useNavigate } from 'react-router-dom';
import { decodeUrl } from '../../helpers';
import { EditSchool } from './EditSchool';
import { ROUTES } from '../../constants';
import { CustomLinkButton } from '../../common/LinkButton';

export const SchoolProfile = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const id = decodeUrl(pathname);
  const { school, schoolEdit, edit, setEdit } = useSchoolProfile(id);
  return !edit ? (
    <>
      <RouteInfo
        withButton
        buttonText="Edit"
        onClick={() => setEdit(true)}
        lastRoute={school?.name}
      />
      <SchoolInfoContainer>
        <WhiteContainer>
          <SchoolInfoCardContent>
            <Flex column p={20} gap={30}>
              <CardImage size={35} image={school?.imageUrl} color={school?.hexColor} />
              <CustomText bold={700} fontSize={1.5}>
                {school?.name}
              </CustomText>
            </Flex>
            <Flex column noFlex>
              <UserCountCard
                title="STUDENTS"
                total={school?.studentCount}
                color={theme.color.darkerBlue}
              />
              <UserCountCard
                title="PARENTS"
                total={school?.parentCount}
                color={theme.color.parentYellow}
              />
              <UserCountCard
                title="STUFF"
                total={school?.staffCount}
                color={theme.color.schollBillRed}
              />
              <LastUserCountCard
                title="TOTAL"
                total={school?.studentCount + school?.parentCount + school?.staffCount}
              />
              <Flex>
                <CustomLinkButton
                  width="70%"
                  href="https://school-pupil-wallet-dev.web.app/"
                  target={'_blank'}
                >
                  School console
                </CustomLinkButton>

                <CustomButton
                  width="30%"
                  bgcolor={theme.color.borderGrey}
                  textcolor={theme.palette.common.black}
                  onClick={() => navigate('.' + ROUTES.INVOICES)}
                >
                  Invoices
                </CustomButton>
              </Flex>
            </Flex>
          </SchoolInfoCardContent>
        </WhiteContainer>
        <WhiteContainer padding="0px" noFlex sx={{ height: 'fit-content' }}>
          <SchoolInfoCardContent>
            <InfoTitle title="School Information" />
            <Flex p={20} noCenter column gap={16}>
              <InfoItem title="Address" content={school?.address} />
              <InfoItem title="Phone" content={school?.phoneNumber} />
              <InfoItem title="Founded" content={school?.founded} />
              <InfoItem title="School types:" content={school?.type} />
              <InfoItem title="Website" content={school?.website} />
            </Flex>
          </SchoolInfoCardContent>
        </WhiteContainer>
        <WhiteContainer padding="0px" noFlex sx={{ height: 'fit-content' }}>
          <SchoolInfoCardContent>
            <InfoTitle title="Payout Information" />
            <Flex p={20} noCenter column gap={16}>
              <InfoItem title="Country" content={school?.bank?.country} />
              <InfoItem title="Bank Name" content={school?.bank?.name} />
              <InfoItem title="Account Number" content={school?.bank?.accountNumber} />
              <InfoItem title="Swift Code" content={school?.bank?.swiftCode} />
              <InfoItem title="Iban Number" content={school?.bank?.ibanNumber} />
            </Flex>
          </SchoolInfoCardContent>
        </WhiteContainer>
      </SchoolInfoContainer>
    </>
  ) : (
    <EditSchool school={school} setEdit={setEdit} schoolEdit={schoolEdit} />
  );
};
