import { InvoiceCard } from '../../components/Card/Card';
import { CardSkeletonContainer, SchoolsPageContainer } from '../Schools/Schools.style';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { useInvoices } from '../../hooks/useInvoices';
import { CustomButton, FiltersContainer, TabsContainer } from '../../common';
import { Filter } from '../../svg/Filter';
import { useState } from 'react';
import { InvoiceFilter } from './InvoiceFilter';
import { Skeleton } from '@mui/material';
import { AddInvoice } from './AddInvoice';

export const Invoices = () => {
  const [addInvoice, setAddInvoice] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filter, setFilter] = useState<{
    from: number | null;
    to: number | null;
    schoolId: string | null;
  }>({
    from: null,
    to: null,
    schoolId: null,
  });
  const { invoices, isLoading, mutationAdd } = useInvoices(filter);
  return !addInvoice ? (
    <>
      <RouteInfo withButton onClick={() => setAddInvoice(true)} />
      <FiltersContainer>
        <TabsContainer width="95%"></TabsContainer>
        <CustomButton content={<Filter />} onClick={() => setFilterModalOpen(true)} />
      </FiltersContainer>
      <SchoolsPageContainer>
        {isLoading &&
          [...Array(12)].map(() => (
            <CardSkeletonContainer>
              <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
            </CardSkeletonContainer>
          ))}
        {invoices && invoices?.map((invoice: any) => <InvoiceCard {...invoice} key={invoice.id} />)}
        <InvoiceFilter
          open={filterModalOpen}
          onSave={(value) => {
            setFilter(value);
            setFilterModalOpen(false);
          }}
          handleClose={() => setFilterModalOpen(false)}
        />
      </SchoolsPageContainer>
    </>
  ) : (
    <AddInvoice navigateBack={() => setAddInvoice(false)} mutationAdd={mutationAdd} />
  );
};
