import { Tab } from '@mui/material';
import React from 'react';
import { FiltersContainer, TabsContainer } from '../../common';

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface MailControlProps {
  changeValue: any;
  tabs: string[];
  activeTab: number;
}

export const MailControl: React.FC<MailControlProps> = ({ changeValue, activeTab, tabs }) => (
  <FiltersContainer>
    <TabsContainer width="100%" value={activeTab} onChange={changeValue}>
      {tabs.map((item: string, index: number) => (
        <Tab key={index} label={item} {...a11yProps(index)} />
      ))}
    </TabsContainer>
  </FiltersContainer>
);
