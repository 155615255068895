import axios from '../config/axios';
import { SchoolAddType, UserAddType } from '../types';

interface CredentialsLogin {
  email: string;
  password: string;
}

export const login = async (data: CredentialsLogin) => {
  const res = await axios({
    method: 'post',
    url: '/authorization/signin',
    data,
  });

  return res;
};

export const getUsers = async (type?: string) => {
  const res = await axios({
    method: 'get',
    url: `/user/search?role=${type}&page=1&pageCount=20`,
  });

  return res;
};

export const getUserId = async (userId: string) => {
  const res = await axios({
    method: 'get',
    url: `/user/uid/${userId}`,
  });

  return res;
};

// Groups

export const getGroups = async () => {
  const res = await axios({
    method: 'get',
    url: '/group/search',
  });

  return res;
};

export const getGroupId = async (groupId: string) => {
  const res = await axios({
    method: 'get',
    url: `/group/groupId/${groupId}`,
  });

  return res;
};

export const deleteGroup = async (groupId: string) => {
  const res = await axios({
    method: 'delete',
    url: `/group/groupId/${groupId}`,
  });
  console.log(res);

  return res;
};

export const createUser = async (data: UserAddType) => {
  const res = await axios({
    method: 'post',
    url: '/user',
    data,
  });
  return res;
};

// Mails

export const getMails = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: '/mail/all',
    params,
  });

  return res;
};

export const favoriteMail = async (mailId: string, favorite: boolean) => {
  const res = await axios({
    method: 'put',
    url: '/mail/favorite',
    data: { mailId, favorite },
  });

  return res;
};

export const composeMail = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: '/mail/compose-admin',
    data,
  });
  return res;
};
// transactions

export const getSchoolBillTransactions = async (id: string) => {
  const res = await axios({
    method: 'get',
    url: `transaction/parent/${id}/school-bill`,
  });

  return res;
};

// overview

export const getAnalytics = async (analytic: string, filter?: any) => {
  let params: any;
  if (filter) {
    params = { ...filter };
    Object.keys(params).forEach((key: string) => {
      if (params[key] === '') {
        delete params[key];
      }
    });
  }
  const res = await axios({
    method: 'get',
    url: `/analytics/admin/${analytic}`,
    params,
  });

  return res;
};

// schools

export const createSchool = async (data: SchoolAddType) => {
  const res: any = await axios({
    method: 'post',
    url: '/school',
    data,
  });

  return res;
};

export const editSchool = async (data: SchoolAddType) => {
  const res = await axios({
    method: 'put',
    url: '/school',
    data,
  });
  return res.data;
};

export const getSchools = async () => {
  const res = await axios({
    method: 'get',
    url: '/school/all',
  });

  return res;
};

export const getSchool = async (id: string) => {
  const res = await axios({
    method: 'get',
    url: `/school/${id}`,
  });

  return res;
};

// external support
export const getExternalSupport = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: 'support/ticket/all-external',
    params,
  });

  return res;
};

export const getTicket = async (ticketId: string) => {
  const res = await axios({
    method: 'get',
    url: `support/ticket`,
    params: {
      ticketId,
    },
  });

  return res;
};

export const sendReply = async (ticketId: string, message: string) => {
  const res = await axios({
    method: 'post',
    url: `support/ticket/reply`,
    data: {
      ticketId,
      message,
    },
  });

  return res;
};

export const exportToExternal = async (ticketId: string) => {
  const res = await axios({
    method: 'post',
    url: `support/ticket/export-external`,
    data: {
      ticketId,
    },
  });

  return res;
};

export const resolveTicket = async (data?: { ticketId?: string; enabled: boolean }) => {
  const res = await axios({
    method: 'post',
    url: `support/ticket/resolved`,
    data,
  });

  return res;
};

// invoices

export const getInvoices = async (params: any) => {
  const res = await axios({
    method: 'get',
    url: `invoice/all`,
    params,
  });

  return res;
};
export const getInvoice = async (id: string) => {
  const res = await axios({
    method: 'get',
    url: `invoice/${id}`,
  });

  return res;
};
export const getInvoicePdf = async (id: string) => {
  const res = await axios({
    method: 'get',
    url: `invoice/pdf/${id}`,
  });

  return res;
};

export const createInvoice = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: '/invoice',
    data,
  });
  return res.data;
};
