import { useTheme } from '@mui/material';
import React from 'react';
import { Flex, WhiteContainer, CustomText } from '../../../common';

export const InvoiceItemDisplay: React.FC<{ name: string; price: number; quantity: number }> = ({
  name,
  price,
  quantity,
}) => {
  const theme = useTheme();
  return (
    <WhiteContainer>
      <Flex flex={3}>{name}</Flex>
      <Flex flex={2}>
        <Flex flex={1}>£{price / 100}</Flex>
        <Flex flex={1}>{quantity}</Flex>
        <Flex flex={1}>
          <CustomText color={theme.color.darkerBlue}>£{(price * quantity) / 100}</CustomText>
        </Flex>
      </Flex>
    </WhiteContainer>
  );
};

export const ItemDisplayHeader = () => {
  return (
    <Flex p="10px 20" gap="0">
      <Flex flex={3}>
        <CustomText bold={700}>Item Name</CustomText>
      </Flex>
      <Flex flex={2}>
        <Flex flex={1}>
          <CustomText bold={700}>Price</CustomText>
        </Flex>
        <Flex flex={1}>
          <CustomText bold={700}>Qty</CustomText>
        </Flex>
        <Flex flex={1}>
          <CustomText bold={700}>Amount</CustomText>
        </Flex>
      </Flex>
    </Flex>
  );
};
