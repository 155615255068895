import { Typography, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import {
  FormContainer,
  PageContainer,
  TopImageContainer,
  BottomImageContainer,
} from './Login.style';
import { useAuth } from '../../hooks/useAuth';
import { CustomButton, CustomText } from '../../common';

import { Flex } from '../../common/Containers';
import { useState } from 'react';
import { LoginLogo } from '../../svg/LoginLogo';

interface LoginForm {
  email: string;
  password: string;
}

export const Login = () => {
  const { values, onChange } = useForm<LoginForm>({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { loginUser, authError } = useAuth();

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    await loginUser(values.email, values.password);
    setLoading(false);
  };

  return (
    <PageContainer>
      <TopImageContainer src="/blueWave.png">
        <Flex>
          <LoginLogo />
          <CustomText color="white" fontSize={1.5} bold={700}>
            Sign in{' '}
          </CustomText>
          <CustomText color="white" fontSize={1.5}>
            to your dashboard
          </CustomText>
        </Flex>
      </TopImageContainer>
      <BottomImageContainer src="/whiteWave.png" />
      <FormContainer component="form" onSubmit={onSubmit}>
        <TextField
          type="email"
          label="Email"
          placeholder="Email"
          name="email"
          variant="outlined"
          value={values.email}
          onChange={onChange}
        />
        <TextField
          type="password"
          label="Password"
          placeholder="Password"
          name="password"
          variant="outlined"
          value={values.password}
          onChange={onChange}
        />
        <CustomButton type="submit" loading={loading} color="primary">
          Submit
        </CustomButton>
        <Flex contectCenter noFlex>
          {authError && <Typography>{authError}</Typography>}
        </Flex>
      </FormContainer>
    </PageContainer>
  );
};
