import React from 'react';

export const School: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="54" height="56" viewBox="0 0 54 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36 26.0001V10.4901C36 8.90006 35.37 7.37006 34.23 6.26006L29.1 1.13006C27.93 -0.0399365 26.04 -0.0399365 24.87 1.13006L19.77 6.23006C18.63 7.37006 18 8.90006 18 10.4901V14.0001H6C2.7 14.0001 0 16.7001 0 20.0001V50.0001C0 53.3001 2.7 56.0001 6 56.0001H48C51.3 56.0001 54 53.3001 54 50.0001V32.0001C54 28.7001 51.3 26.0001 48 26.0001H36ZM12 50.0001H6V44.0001H12V50.0001ZM12 38.0001H6V32.0001H12V38.0001ZM12 26.0001H6V20.0001H12V26.0001ZM30 50.0001H24V44.0001H30V50.0001ZM30 38.0001H24V32.0001H30V38.0001ZM30 26.0001H24V20.0001H30V26.0001ZM30 14.0001H24V8.00006H30V14.0001ZM48 50.0001H42V44.0001H48V50.0001ZM48 38.0001H42V32.0001H48V38.0001Z"
      fill={color ? color : '#0C84FC'}
    />
  </svg>
);
