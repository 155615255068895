import { Box, CircularProgress, styled } from '@mui/material';
import React from 'react';

const LoadingContainer = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
`;

export const Loading = () => (
  <LoadingContainer>
    <CircularProgress />
  </LoadingContainer>
);
