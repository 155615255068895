import React from 'react';
import { WhiteContainer, Flex } from '../../../common';
import { InputField } from '../../../components/Inputs';
import { SmallCircle } from '../../../components/RouteInfo/RouteInfo.style';
import { FullPlusCircle } from '../../../svg/FullPlusCircle';
import { IconButton } from '@mui/material';

export const AddInvoiceItem: React.FC<{
  onChange: any;
  index: number;
  values: { name: string; quantity: number; price: number };
}> = ({ onChange, index, values }) => {
  return (
    <WhiteContainer noFlex>
      <Flex gap={20}>
        <Flex flex={3}>
          <InputField
            type="text"
            name="name"
            info={`Item ${index + 1}`}
            padding="4px 20px"
            placeholder="Enter here..."
            fullHeight
            onChange={(e: any) => onChange('name', index, e.target.value)}
            value={values.name}
          />
        </Flex>
        <Flex flex={2} gap={20}>
          <Flex>
            <InputField
              type="number"
              name="name"
              info="Quantity"
              padding="4px 20px"
              placeholder="1"
              fullHeight
              onChange={(e: any) => onChange('quantity', index, Math.abs(Number(e.target.value)))}
              value={values.quantity}
            />
          </Flex>
          <Flex>
            <InputField
              type="number"
              name="price"
              info="Price"
              padding="4px 20px"
              placeholder="£00000"
              fullHeight
              onChange={(e: any) => {
                onChange('price', index, Math.abs(Number(e.target.value * 100)));
              }}
              value={values.price / 100}
            />
          </Flex>
          <Flex>
            {' '}
            <InputField
              type="text"
              name="name"
              info="Amount"
              padding="4px 20px"
              placeholder="£00000"
              fullHeight
              disabled
              value={(values.price * values.quantity) / 100}
            />
          </Flex>
        </Flex>
      </Flex>
    </WhiteContainer>
  );
};

export const AddItemButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <IconButton size="small" onClick={onClick}>
      <SmallCircle size={48}>
        <FullPlusCircle />
      </SmallCircle>
    </IconButton>
  );
};
