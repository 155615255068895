import React, { useRef } from 'react';
import { Flex } from '../../common';
import { InputField } from './Input';
import { InputFieldProps } from './Input';

export const ColorPicker: React.FC<InputFieldProps> = ({ value, onChange, ...rest }) => {
  const ref: any = useRef(null);
  return (
    <Flex>
      <InputField
        id="groupName"
        padding="4px 20px"
        value={value}
        type="text"
        onClick={() => ref.current.click()}
        inputProps={{
          style: {
            color: value,
            fontWeight: 700,
            textTransform: 'uppercase',
          },
        }}
        {...rest}
      />
      <input
        type="color"
        ref={ref}
        style={{ opacity: 0, position: 'absolute', bottom: '0', right: 0 }}
        onChange={onChange}
      />
    </Flex>
  );
};
