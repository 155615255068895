import React from 'react';
import moment from 'moment';
import { OverviewItem } from '../../pages/Overview/Overview.style';
import { ChatrTitle, CustomText } from '../../common';
import { IconContainer } from '../../common';
import { Wallet } from '../../svg/Wallet';
import { ContainerContent } from './ChartContainer.style';
import { useTheme } from '@mui/material';
import { ChartTitle } from '../../common/ChartTitle';
import CountUp from 'react-countup';
export const Earnings: React.FC<{ value: number }> = ({ value }) => {
  const theme = useTheme();
  return (
    <OverviewItem>
      <ChartTitle
        title="Earnings"
        description={moment().format('DD / MMMM / YYYY')}
        withoutButton
      />
      <ContainerContent>
        <IconContainer size="100px" p="10px">
          <Wallet />
        </IconContainer>
        <div>
          <CustomText fontSize={1.9} bold={700} color={theme.color.darkerBlue}>
            £{<CountUp end={value / 100} />}
          </CustomText>
        </div>
      </ContainerContent>
    </OverviewItem>
  );
};
