import { Box, styled } from '@mui/material';

export const ContainerContent = styled(Box)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  padding: 30px;
`;
