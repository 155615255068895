import React from 'react';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { Flex, WhiteContainer } from '../../common';
import { BackTitle } from '../../components/BackTitle';
import { ROUTES } from '../../constants';
import { Left } from './AddInvoiceContainers/Left';
import { Right } from './AddInvoiceContainers/Right';
import { useForm } from '../../hooks/useForm';
import { defaultValue } from './AddInvoiceContainers/defaultText';
import moment from 'moment';
import { useInvoices } from '../../hooks/useInvoices';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../components/Loading';

export const AddInvoice: React.FC<{ navigateBack: any; mutationAdd: any }> = ({
  mutationAdd,
  navigateBack,
}) => {
  const { values, onChange, forceChange } = useForm({
    schoolId: null,
    date: moment().unix(),
    vatPercentage: 20,
    description: defaultValue,
    items: [
      {
        name: '',
        quantity: null,
        price: null,
      },
    ],
  });

  const handleSubmit = async () => {
    await mutationAdd.mutateAsync(values);
    navigateBack();
  };

  return (
    <>
      <RouteInfo />
      <Flex column p="20" fullHeight>
        <BackTitle title="Add Invoice" onClick={() => navigateBack()} />
        <Flex fullHeight gap={80}>
          <Left values={values} onChange={onChange} forceChange={forceChange} />
          <Right
            values={values}
            onChange={onChange}
            handleSubmit={handleSubmit}
            forceChange={forceChange}
            loading={mutationAdd.isLoading}
          />
        </Flex>
      </Flex>
    </>
  );
};
