import { Modal, Paper, styled } from '@mui/material';

export const LogoutModal = styled(Modal)`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoutContent = styled(Paper)`
  height: 35%;
  width: 29%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 30px;
  border-radius: 10px;
  position: relative;

  button:nth-child(1) {
    color: ${({ theme }) => theme.palette.common.black};
  }
`;
