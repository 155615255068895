import { useState } from 'react';
import { SchoolsPageContainer, CardSkeletonContainer } from './Schools.style';
import { Card } from '../../components/Card/Card';
import { Skeleton } from '@mui/material';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { useSchools } from '../../hooks/useSchools';

export interface SimpleSchool {
  address: string;
  hexColor: string;
  id: string;
  image: string;
  name: string;
  usersCount: number;
}

export const Schools = () => {
  const navigate = useNavigate();
  const { schools, isLoading } = useSchools();

  return (
    <>
      <RouteInfo withButton onClick={() => navigate(ROUTES.ADDSCHOOL)} />
      <SchoolsPageContainer>
        {!isLoading ? (
          schools.map((school: SimpleSchool) => <Card {...school} />)
        ) : (
          <>
            {[...Array(12)].map(() => (
              <CardSkeletonContainer>
                <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
              </CardSkeletonContainer>
            ))}
          </>
        )}
      </SchoolsPageContainer>
    </>
  );
};
