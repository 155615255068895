import React from 'react';
import { styled, Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { SmallCircle } from '../components/RouteInfo/RouteInfo.style';
import { CustomText } from './TextStyles';

export const TitleContainer = styled(Box)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGrey};
`;

export const TitleTextAndIcon = styled(Box)`
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
`;

export const TitleInnerContainer = styled(Box)`
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ChartTitleFilter = styled(CustomText)`
  text-transform: capitalize;
`;

interface ChartTitleTypes {
  title: string;
  date?: number | null;
  year?: number;
  color?: string;
  icon?: any;
  iconColor?: string;
  onClick?: () => void;
  filter?: any;
  description?: string;
  withoutButton?: boolean;
  children?: React.ReactNode;
}

export const ChartTitle: React.FC<ChartTitleTypes> = ({
  title,
  year,
  date,
  color,
  icon,
  iconColor,
  onClick,
  filter,
  description,
  withoutButton,
  children,
}) => {
  // console.log(filter.keys);
  return (
    <TitleContainer>
      <TitleInnerContainer>
        <TitleTextAndIcon>
          {icon && (
            <SmallCircle color={color}>
              {React.cloneElement(icon, { color: iconColor ? iconColor : 'white' })}
            </SmallCircle>
          )}
          <div>
            <CustomText bold={700} fontSize={1.2}>
              {title}
            </CustomText>
            <ChartTitleFilter bold={400} fontSize={0.9}>
              {filter &&
                Object.keys(filter).map((type: string, i: number) => {
                  if (i < Object.keys(filter).length - 1) {
                    if (filter[type]) {
                      return filter[type] + ' / ';
                    }
                    return 'all ' + type + 's / ';
                  } else {
                    if (filter[type]) {
                      return filter[type];
                    }
                    return 'all ' + type + 's';
                  }
                })}
              {description && description}
            </ChartTitleFilter>
          </div>
        </TitleTextAndIcon>
        {!withoutButton && (
          <IconButton size="large" onClick={onClick}>
            <MenuIcon />
          </IconButton>
        )}
        {children}
      </TitleInnerContainer>
    </TitleContainer>
  );
};
