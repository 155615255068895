import React from 'react';

export const Notation: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.0001 0.166626C16.2201 0.166626 0.166748 16.22 0.166748 36C0.166748 55.78 16.2201 71.8333 36.0001 71.8333C55.7801 71.8333 71.8334 55.78 71.8334 36C71.8334 16.22 55.7801 0.166626 36.0001 0.166626ZM36.0001 53.9166C34.0293 53.9166 32.4167 52.3041 32.4167 50.3333V36C32.4167 34.0291 34.0293 32.4166 36.0001 32.4166C37.9709 32.4166 39.5834 34.0291 39.5834 36V50.3333C39.5834 52.3041 37.9709 53.9166 36.0001 53.9166ZM39.5834 25.25H32.4167V18.0833H39.5834V25.25Z"
      fill={color ? color : '#0C84FC'}
    />
  </svg>
);
