import { useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import { CustomText, Flex } from '../../common';
import { CardImage } from '../../components/Card/Card.style';
import { getBase64 } from '../../helpers/imageToBase64';
import { PlusCircle } from '../../svg/PlusCircle';

export const ImageInput: React.FC<{
  handleChange: any;
  schoolImage?: string;
  hexColor?: string;
}> = ({ handleChange, schoolImage, hexColor }) => {
  const theme = useTheme();
  const [imageUrl, setImageUrl] = useState('');
  const handleInputChange = async (e: any) => {
    const url: any = await getBase64(e.target.files[0]);
    const formattedBinary = url.split(',')[1];
    const type = url.split(';')[0].split(':')[1];
    console.log({ type, data: formattedBinary });
    setImageUrl(url);
    handleChange?.({
      type,
      data: formattedBinary,
    });
  };
  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };
  const hiddenFileInput: any = useRef();
  return (
    <Flex>
      <CardImage
        color={hexColor}
        onClick={handleClick}
        size={40}
        image={imageUrl || schoolImage || <PlusCircle color={theme.color.darkerBlue} size={70} />}
      />
      <Flex column noCenter contectCenter>
        <CustomText bold={700}>SCHOOL LOGO</CustomText>
        <CustomText>Info about photo size and format.</CustomText>
      </Flex>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleInputChange}
        style={{ display: 'none' }}
      />
    </Flex>
  );
};
