import { Star, StarBorder } from '@mui/icons-material';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import moment from 'moment';
import React from 'react';

import {
  CustomButton,
  CustomText,
  DateText,
  MessagesContainer,
  PersonInfoContainer,
  PersonInfoContent,
  StyledAvatar,
} from '../../common';
import { SUPPORT_DATE } from '../../constants';
import { MuiIcon } from '../Mail/Mail.style';

interface MailMessageProps {
  user: any;
  email: string;
  subject: string;
  date: number;
  isFavorite: boolean;
  sentType: string;
  body?: string;
}

export const MailMessage: React.FC<MailMessageProps> = ({
  user,
  email,
  subject,
  date,
  sentType,
  isFavorite,
  body,
}) => (
  <MessagesContainer bottom="ok">
    <PersonInfoContainer>
      <PersonInfoContent gap={19}>
        <MuiIcon>{isFavorite ? <Star /> : <StarBorder />}</MuiIcon>
        <StyledAvatar height={82} width={82} />
        <Box>
          <CustomText fontSize={1.2} bold={700}>
            {sentType === 'inbox' ? 'FROM' : 'TO'}: {user.name}
          </CustomText>
          <CustomText>{email}</CustomText>
        </Box>
      </PersonInfoContent>
      <DateText>{moment.unix(date).format('DD.MM.YYYY, HH:MM')}</DateText>
    </PersonInfoContainer>

    <CustomText bold={700} margin="2% 0">
      {subject}
    </CustomText>

    <CustomText bold={300}>{body}</CustomText>
  </MessagesContainer>
);
