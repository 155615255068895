import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getInvoice } from '../api';

export const useInvoice = (id: string) => {
  const [invoice, setInvoice] = useState<any>([]);
  const { data, isLoading } = useQuery(['invoice', id], () => getInvoice(id));

  useEffect(() => {
    if (data) {
      console.log(data);
      setInvoice(data.data.data);
    }
  }, [data]);

  return {
    invoice,
    isLoading,
  };
};
