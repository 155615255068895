export const defaultValue = `Payments from the United Kingdom:

Account Holder Name: PW Applications Limited 
Account Number: 08647646
Sort Code: 60-83-71

International Payments:

Account Holder Name: PW Applications Limited 
IBAN: GB28SRLG60837108647646
SWIFT/ BIC: SRLGGB2L`;
