import React from 'react';
import { CustomText, Flex } from '../../common';

export const MailListHeader = () => {
  return (
    <Flex p="10px 20">
      <Flex flex={15}></Flex>
      <Flex flex={20}>
        <CustomText bold={700}>Type</CustomText>
      </Flex>
      <Flex flex={10}>
        <CustomText bold={700}>Date</CustomText>
      </Flex>
      <Flex flex={10}>
        <CustomText bold={700}>Time</CustomText>
      </Flex>
    </Flex>
  );
};
