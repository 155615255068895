import { styled, Box } from '@mui/material';

export const WhiteContainer = styled(Box)<{
  jcontent?: string;
  gap?: number;
  padding?: string;
  margin?: string;
  noFlex?: boolean;
}>`
  width: 100%;
  flex: ${({ noFlex }) => (noFlex ? 0 : 1)};
  display: flex;
  align-items: center;
  justify-content: ${({ jcontent }) => jcontent || 'space-between'};
  gap: ${({ gap }) => `${gap}px` || '0'};
  padding: ${({ padding }) => padding || '20px'};
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  background-color: ${({ theme }) => theme.palette.common.white};
  margin: ${({ margin }) => margin || '0'};
  position: relative;

  &:hover {
    outline: 1px solid ${({ theme }) => theme.color.lightBlue};
  }

  svg {
    cursor: pointer;
    transition: all 0.4s;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FlexRowPadding = styled(Box)<{ p?: number; gap?: number }>`
  display: flex;
  flex-direction: column;
  padding: ${({ p }) => (p ? p : 16)}px;
  gap: ${({ gap }) => (gap ? gap : 0)}px;
  justify-content: space-between;
  flex: 1;
`;

export const Flex = styled(Box)<{
  gap?: number | string;
  column?: boolean;
  noCenter?: boolean;
  contectCenter?: boolean;
  p?: number | string;
  noFlex?: boolean;
  flexEnd?: boolean;
  flex?: number;
  spaceBetween?: boolean;
  fullHeight?: boolean;
  alignEnd?: boolean;
}>`
  display: flex;
  position: relative;
  width: 100%;
  flex: ${({ noFlex, flex }) => (noFlex ? '0' : flex ? flex : 1)};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  align-items: ${({ noCenter, alignEnd }) =>
    alignEnd ? 'flex-end' : noCenter ? 'flex-start' : 'center'};
  gap: ${({ gap }) => (gap ? gap : 16)}px;
  justify-content: ${({ contectCenter, flexEnd, spaceBetween }) =>
    contectCenter
      ? 'center'
      : flexEnd
      ? 'flex-end'
      : spaceBetween
      ? 'space-between'
      : 'flex-start'};
  padding: ${({ p }) => (p ? p : 0)}px;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'fit-content')};
`;

export const FiltersContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 4px;
  }
`;
