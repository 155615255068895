import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Icon } from '@mui/material';
import React from 'react';
import { Flex, CustomText } from '../../common';

export const ExternalSupportHeaderInbox = () => (
  <Flex p="0 20" gap="0px">
    <Flex flex={1}>
      <CustomText bold={700}>School Name</CustomText>
    </Flex>
    <Flex flex={1}>
      <CustomText bold={700}>Subject</CustomText>
    </Flex>
    <Flex flex={1}>
      <CustomText bold={700}>Date</CustomText>
    </Flex>
    <Flex flex={1}>
      <CustomText bold={700}>Time</CustomText>
    </Flex>
    <Flex flex={1}>
      <CustomText bold={700}>Status</CustomText>
    </Flex>
  </Flex>
);
