import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getTicket, sendReply, exportToExternal, resolveTicket } from '../api';

export const useTicket = (id: string) => {
  const queryClient = useQueryClient();
  const [ticket, setTicket] = useState<null | {
    agent: null | string;
    createdBy: { uid: string; name: string; imageUrl: string };
    date: number;
    exportExternal: boolean;
    id: string;
    messages: {
      body: string;
      date: number;
      from: { photo: string; name: string; email: string };
      id: string;
      isFromExternalSupport: boolean;
      subject: string;
    }[];
    resolved: boolean;
  }>(null);
  const { data, isLoading, refetch } = useQuery(['ticket', id], () => getTicket(id));

  // reply

  const reply = async (message: string) => {
    const res = await sendReply(id, message);
    console.log(res);
    if (res.status === 200) {
      refetch();
      return res;
    }
  };

  // resolveTicket

  const ticketResolve = useMutation(resolveTicket, {
    onSuccess: () => {
      queryClient.invalidateQueries(['ticket']);
      // notif({ message: 'Invoice created successfuly!' });
      // navigate(ROUTES.INVOICES);
    },
    onError: (e: any) => {
      // notifyError('Please fill all details!');
    },
  });

  const exportTicket = async () => {
    const res = await exportToExternal(id);
    console.log(res);
    if (res.status === 200) {
      refetch();
      return res;
    }
  };

  useEffect(() => {
    if (data) {
      console.log(data);
      setTicket(data.data.data);
    }
  }, [data]);

  return {
    ticket,
    isLoading,
    reply,
    exportTicket,
    ticketResolve,
  };
};
