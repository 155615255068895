import { Box, styled } from '@mui/material';

export const SchoolsPageContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  gap: 20px;
  padding: 20px;
`;

export const CardSkeletonContainer = styled(Box)`
  width: 100%;
  aspect-ratio: 1/1.4;
  border-radius: 10px;
  overflow: hidden;
`;

export const SchoolInfoContainer = styled(Box)`
  flex: 1;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
`;

export const SchoolInfoCardContent = styled(Box)`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const AddEditSchoolContainer = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const AddEditSchoolContent = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr auto 1fr;
  gap: 40px;
`;
