import { styled, Box, Typography } from '@mui/material';

export const CircleContainer = styled(Box)<{ height?: string }>`
  height: ${({ height }) => height || '100%'};
  display: flex;
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  align-items: center;
  gap: 16px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
`;

export const Circle = styled(Box)<{ invert?: boolean }>`
  display: flex;
  width: 10rem;
  min-height: 10rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ invert, theme }) =>
    invert ? theme.palette.common.white : theme.color.darkerBlue};

  svg {
    max-width: 50%;
    max-height: 50%;
  }
`;

export const CardCircle = styled(Box)<{ invert?: boolean }>`
  display: flex;
  min-height: 50%;
  max-height: 50%;
  aspect-ratio: 1/1;
  flex: 2;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ invert, theme }) =>
    invert ? theme.palette.common.white : theme.color.darkerBlue};

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  svg {
    max-width: 50%;
    max-height: 50%;
  }
`;

export const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
`;

export const Banner = styled(Typography)`
  font-size: 1.1rem;
  text-align: center;
  font-weight: 700;
  margin-top: 4px;
`;

export const Desc = styled(Typography)`
  font-size: 0.87rem;
  text-align: center;
  word-break: break-word;
`;
