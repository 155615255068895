import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Flex } from '../../common';
import { FetchButton } from '../../common/CustomButton';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../constants';
import { useExternalSupport } from '../../hooks/useExternalSupport';
import { ExternalSupportControl } from './ExternalSupportControl';

import { ExternalSupportHeaderInbox } from './ExternalSupportHeaderInbox';
import { ExternalSupportItemInbox } from './ExternalSupportItemInbox';
import { ExternalSupportSend } from './ExternalSupportSend';

export const ExternalSupport = () => {
  const navigate = useNavigate();
  const { tickets, fetchNextPage, isFetchingNextPage, hasNextPage, refetch, isLoading } =
    useExternalSupport();

  const selectTicket = (item: any) => {
    navigate(`${ROUTES.EXTERNAL_SUPPORT_TICKET}/${item.id}`);
  };

  return (
    <>
      <RouteInfo />
      <ExternalSupportControl />

      <Flex column noFlex p="20">
        <ExternalSupportHeaderInbox />
        {/* {isLoading && [...Array(20)].map((item: any) => <ExternalSupportItemInboxSkeleton />)} */}
        {tickets &&
          tickets.map((tickets: any) =>
            Object.values(tickets).map((item: any, index: number) => (
              <ExternalSupportItemInbox key={index} {...item} onClick={() => selectTicket(item)} />
            )),
          )}
        {!isLoading && (
          <FetchButton isFetchingNextPage={isFetchingNextPage} onView={fetchNextPage} />
        )}
      </Flex>
    </>
  );
};
