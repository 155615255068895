import React, { useState } from 'react';
import { Flex } from '../../common';
import { FetchButton, FetchingNextPageButton } from '../../common/CustomButton';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { useMails } from '../../hooks/useMails';
import { MailControl } from './MailControl';
import { MailCreate } from './MailCreate';
import { MailItem, MailItemSkeleton } from './MailItem';
import { MailListHeader } from './MailListHeader';

export const Mail = () => {
  const [showModal, setShowModal] = useState(false);
  const {
    mails,
    setActiveTab,
    tabs,
    activeTab,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useMails();

  const changeValue = (event: any, newValue: number) => setActiveTab(newValue);

  return (
    <Flex column noFlex>
      <RouteInfo onClick={() => setShowModal(true)} withButton />
      {showModal && (
        <MailCreate refetch={refetch} isOpen={showModal} onClose={() => setShowModal(false)} />
      )}
      <MailControl changeValue={changeValue} tabs={tabs} activeTab={activeTab} />
      <Flex column p="0 20">
        <MailListHeader />
        {isLoading &&
          [...Array(20)].map((item: any, index: number) => <MailItemSkeleton key={index} />)}
        {mails &&
          mails.map((mails: any) =>
            Object.values(mails).map((mail: any, index: number) => (
              <MailItem
                key={mail.id}
                {...mail}
                user={mail.sentType === 'inbox' ? mail.from : mail.to}
              />
            )),
          )}
        <FetchButton onView={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
      </Flex>
    </Flex>
  );
};
