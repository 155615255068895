import { Box, CircularProgress, FormControlLabel, useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';

import {
  CustomText,
  DateText,
  IOSSwitch,
  MessagesContainer,
  PersonInfoContainer,
  PersonInfoContent,
  StyledAvatar,
} from '../../common';

interface InternalSupportTicketMessagesProps {
  resolved: boolean;
  main: boolean;
  body: string;
  date: number;
  from: { photo: string; name: string; email: string };
  id: string;
  isFromExternalSupport: boolean;
  subject: string;
  exportExternal?: boolean;
  resolve?: any;
  resolveLoading: boolean;
}

export const ExternalSupportMessages: React.FC<InternalSupportTicketMessagesProps> = ({
  resolved,
  main,
  body,
  date,
  from,
  subject,
  resolve,
  resolveLoading,
}) => {
  const theme = useTheme();
  return (
    <MessagesContainer bottom="ok">
      <PersonInfoContainer>
        <PersonInfoContent gap={19}>
          <StyledAvatar height={main ? 82 : 37} width={main ? 82 : 37} src={from.photo} />
          <Box>
            <CustomText fontSize={main ? 1.2 : 1} bold={700}>
              From: {from.name}
            </CustomText>
            <CustomText>{from.email}</CustomText>
          </Box>
        </PersonInfoContent>

        {main &&
          (resolveLoading ? (
            <CircularProgress />
          ) : (
            <FormControlLabel
              control={<IOSSwitch color="success" checked={resolved} onClick={resolve} />}
              label={resolved ? 'Resolved' : 'Unresolved'}
              labelPlacement="start"
            />
          ))}
      </PersonInfoContainer>

      <PersonInfoContainer>
        <PersonInfoContent gap={9}>
          <CustomText bold={700}>Subject:</CustomText>
          <CustomText bold={700}>{subject}</CustomText>
        </PersonInfoContent>

        <DateText>{moment.unix(date).format('MM.DD.YYYY, HH:MM')}</DateText>
      </PersonInfoContainer>

      <CustomText bold={300}>{body}</CustomText>
    </MessagesContainer>
  );
};
