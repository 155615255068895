import React, { useState } from 'react';
import { Divider, IconButton, Modal, TextField, useTheme } from '@mui/material';
import {
  CustomButton,
  CustomText,
  Flex,
  WhiteContainer,
  WhiteModal,
  WhiteModalContent,
} from '../../common';
import { ModalContainer, ModalContent, ModalTitle } from '../../components/Filters/FilterModal';
import CloseIcon from '@mui/icons-material/Close';
import {
  InvoiceItemDisplay,
  ItemDisplayHeader,
} from '../Invoices/InvoiceDetailsContainers/InvoiceItemDisplay';
import { defaultValue } from '../Invoices/AddInvoiceContainers/defaultText';
import { Download } from '../../svg/Download';
import moment from 'moment';
import { getInvoicePdf } from '../../api';
import { useMutation } from '@tanstack/react-query';
import { base64toBlob } from '../../helpers';

export const InvoiceDetailModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  description: string;
  totalItemsPrice: number;
  vatPercentage: number;
  school?: {
    name: string;
    address: string;
    email: string;
  };
  number: string;
  items: { name: string; price: number; quantity: number }[];
  date: number;
  id: string;
}> = ({
  id,
  open,
  handleClose,
  school,
  date,
  items,
  description,
  totalItemsPrice,
  vatPercentage,
  number,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const handlePdfDownload = async () => {
    setLoading(true);
    const res = await getInvoicePdf(id);
    const blob = base64toBlob(res.data.data);
    const url = URL.createObjectURL(blob);
    const downloadLink: any = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = `${school?.name}_${number}`;
    downloadLink.click();
    setLoading(false);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContainer width={95}>
        <ModalTitle>
          <CustomText color={theme.color.darkerBlue} bold={700} fontSize={1.2}>
            INVOICE DETAILS
          </CustomText>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </ModalTitle>
        <ModalContent>
          <Flex spaceBetween>
            <CustomText color={theme.color.darkerBlue} bold={700} fontSize={1.1}>
              {school?.name}
            </CustomText>
            <Flex flexEnd>
              <Flex column alignEnd gap="0">
                <CustomText>Invoice Number</CustomText>
                <CustomText bold={700}>{number}</CustomText>
              </Flex>
              <CustomButton loading={loading} onClick={handlePdfDownload}>
                <Download color="white" />
              </CustomButton>
            </Flex>
          </Flex>
          <Flex noCenter flex={2}>
            <Flex column noCenter>
              <Flex column gap="0" noCenter>
                <CustomText>Date</CustomText>
                <CustomText bold={700}>{moment.unix(date).format('MMM DD, YYYY')}</CustomText>
              </Flex>
              <Flex column gap="0" noCenter>
                <CustomText>Address</CustomText>
                <CustomText bold={700}>{school?.address}</CustomText>
              </Flex>
              <Flex column gap="0" noCenter>
                <CustomText>Email</CustomText>
                <CustomText bold={700}>{school?.email}</CustomText>
              </Flex>
            </Flex>
            <Flex flex={3} column>
              <ItemDisplayHeader />
              {items?.map((item: any) => (
                <InvoiceItemDisplay {...item} />
              ))}
              <WhiteContainer padding="0">
                <TextField
                  type="text"
                  name="description"
                  multiline
                  rows={12}
                  value={description}
                  sx={{
                    width: '100%',
                  }}
                />
              </WhiteContainer>
            </Flex>
          </Flex>
          <Flex>
            <Flex column gap="0" alignEnd flexEnd>
              <Flex alignEnd flexEnd>
                <CustomText>Total Price:</CustomText>
                <CustomText bold={700}>£{totalItemsPrice / 100}</CustomText>
              </Flex>
              <Flex alignEnd flexEnd>
                <CustomText>VAT ({vatPercentage}%):</CustomText>
                <CustomText bold={700}>£{(totalItemsPrice * vatPercentage) / 10000}</CustomText>
              </Flex>
            </Flex>
          </Flex>
          <Divider flexItem />
          <Flex alignEnd flexEnd>
            <CustomText>Total Amount: </CustomText>
            <CustomText bold={700} color={theme.color.depositGreen}>
              £{(totalItemsPrice + (totalItemsPrice * vatPercentage) / 100) / 100}
            </CustomText>
          </Flex>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};
