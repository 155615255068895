import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getSchool, editSchool } from '../api';
import { useNotification } from './useNotification';

export const useSchoolProfile = (id: string) => {
  const { notifySuccess, notifyError } = useNotification();
  const [edit, setEdit] = useState(false);
  const [school, setSchool] = useState<any>(null);
  const { data, isLoading } = useQuery(['school', id], () => getSchool(id));
  const queryClient = useQueryClient();

  const schoolEdit = useMutation(editSchool, {
    onSuccess: () => {
      notifySuccess('School edited successfuly!');
      queryClient.invalidateQueries(['school']);
      setEdit(false);
    },
    onError: (e: any) => {
      notifyError(e.message);
    },
  });

  useEffect(() => {
    if (data) {
      console.log(data);
      setSchool(data.data.data);
    }
  }, [data]);

  return {
    school,
    isLoading,
    schoolEdit,
    edit,
    setEdit,
  };
};
