import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { VendorsContainer, VendorsContent } from './AdminConsole.style';
import { links, Links } from '../../components/AdminConsole/AdminConsole.constants';
import { DashboardCircle, FooterShape } from '../../common';

export const AdminConsole = () => {
  const navigate = useNavigate();
  return (
    <>
      <RouteInfo withButton />
      <VendorsContainer gap={44}>
        <VendorsContent>
          {links.map(
            (link: Links, index: number) =>
              !link.dontShow && (
                <>
                  <DashboardCircle
                    image={link.image}
                    banner={link.banner}
                    descriptionText={link.descriptionText}
                    height="100%"
                    onClick={() => navigate(link.link)}
                    invert={index !== 0}
                  />
                  {index === 0 && <Divider orientation="vertical" />}
                </>
              ),
          )}
        </VendorsContent>
      </VendorsContainer>
      <FooterShape />
    </>
  );
};
