import { Tabs, styled } from '@mui/material';

export const TabsContainer = styled(Tabs)<{ width?: string }>`
  flex: 0 0 ${({ width }) => width || '62%'};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[400]};

  button {
    font-weight: 800;
    color: ${({ theme }) => theme.palette.grey[900]};
  }

  [aria-selected='true'] {
    background: -webkit-linear-gradient(50.23deg, #54b1e5 9.71%, #0c84fc 108.89%);
    -webkit-background-clip: text;
  }

  & .MuiTabs-indicator {
    background: linear-gradient(50.23deg, #54b1e5 9.71%, #0c84fc 108.89%);
  }
`;
