import React, { useEffect } from 'react';
import { useForm } from '../../hooks/useForm';
import { Flex } from '../../common/Containers';
import { CustomButton } from '../../common';
import moment from 'moment';
import { DateInput } from '../Inputs';
import { FilterModal, FilterPropTypes } from './FilterModal';

interface BigFilterPropTypes extends FilterPropTypes {
  onSave: (value: any) => void;
}

export const SmallFillter: React.FC<BigFilterPropTypes> = ({ open, handleClose, onSave }) => {
  const { values, forceChange } = useForm<{
    from: number;
    to: number;
  }>({
    from: moment().startOf('year').unix(),
    to: moment().startOf('second').unix(),
  });
  return (
    <FilterModal open={open} handleClose={handleClose}>
      <Flex column>
        <Flex>
          <DateInput
            value={moment.unix(values.from)}
            onChange={(e: any) => {
              forceChange(moment(e).unix(), 'from');
            }}
            name="from"
            id="from"
            info="From"
          />
          <DateInput
            value={moment.unix(values.to)}
            onChange={(e: any) => {
              forceChange(moment(e).unix(), 'to');
            }}
            name="to"
            id="to"
            info="To"
          />
        </Flex>
        <Flex>
          <CustomButton
            content="confirm"
            width="100%"
            height="56px"
            onClick={() => onSave(values)}
          />
        </Flex>
      </Flex>
    </FilterModal>
  );
};
