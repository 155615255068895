import { styled, Icon } from '@mui/material';

export const MuiIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;

  &:hover {
    color: blue;
  }
`;
