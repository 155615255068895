import React, { ReactNode } from 'react';
import { Banner, Circle, CircleContainer, Desc, TextContainer } from './DashboardCircle.style';

export interface DashboardCircleProps {
  image: ReactNode | string;
  banner: string;
  descriptionText?: string;
  invert?: boolean;
  height?: string;
  onClick?: () => void;
}

export const DashboardCircle: React.FC<DashboardCircleProps> = ({
  image,
  banner,
  descriptionText,
  invert,
  height,
  onClick,
}) => (
  <CircleContainer height={height} onClick={onClick}>
    <Circle invert={invert}>{image}</Circle>
    <TextContainer>
      <Banner variant="h5">{banner}</Banner>
      <Desc>{descriptionText}</Desc>
    </TextContainer>
  </CircleContainer>
);
