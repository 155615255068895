import { Modal, styled, Box } from '@mui/material';

export const WhiteModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WhiteModalContent = styled(Box)`
  min-height: 20vh;
  max-height: 80vh;
  min-width: 40vw;
  overflow: scroll;
  border-radius: 10px;
  padding: 20px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    width: 90%;
  }
`;
