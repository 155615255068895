import { Box, Icon, styled } from '@mui/material';

export const MailListItem = styled(Box)<{ padding?: string; cursor?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: ${({ padding }) => padding || '20px 10px'};
  border-radius: 10px;
  cursor: ${({ cursor }) => cursor};
  position: relative;

  &:hover {
    outline: 1px solid ${({ theme }) => theme.color.lightBlue};
  }

  @media (max-width: 1024px) {
    align-items: flex-start;
    flex-direction: column;

    div:nth-child(2) {
      flex-direction: column;
    }

    div:last-of-type {
      justify-content: flex-end;
      position: absolute;
      top: 50%;
      right: 35px;
      transform: translateY(-50%);
    }
  }
`;

export const Marker = styled(Box)<{ bgColor?: boolean }>`
  height: 75%;
  width: 6px;
  border-radius: 0 6px 6px 0;
  background-color: ${({ theme, bgColor }) => bgColor && theme.palette.primary.light};
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const MailListItemContent = styled(Box)<{ width: string; margin?: string }>`
  height: 100%;
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin || '0'};
  position: relative;

  @media (max-width: 1024px) {
    width: 90%;
  }
`;

export const MuiIcon = styled(Icon)`
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;

  &:hover {
    color: blue;
  }
`;
