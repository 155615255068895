import { styled, Switch } from '@mui/material';

export const IOSSwitch = styled(Switch)`
  height: 26px;
  width: 42px;
  padding: 0;
  border-radius: 16px;
  margin: 0 0 0 20px;

  & .MuiSwitch-switchBase {
    padding: 0;
    margin: 3px 0;
    transition-duration: 300;

    &.Mui-checked {
      transform: translateX(18px);
      color: #fff;
      &.MuiSwitch-track {
        background-color: red;
        opacity: 1;
        border: 0;
      }
      & .mui-disabled + .muiswitch-track: {
        opacity: 0.5;
        background-color: red;
      }
    }
  }
`;
