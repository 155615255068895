import { Divider, MenuItem, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { composeMail } from '../../api';
import { CustomButton, CustomText, Flex, WhiteModal, WhiteModalContent } from '../../common';
import { AttachFile, InputField, SelectField } from '../../components/Inputs';
import { AutocompleteInput } from '../../components/Inputs/AutocompleteInput';
import { SchoolSelect } from '../../components/Inputs/SchoolSelect';
import { removeEmpty } from '../../helpers';
import { useForm } from '../../hooks/useForm';
import { useNotification } from '../../hooks/useNotification';
import { useSchools } from '../../hooks/useSchools';

interface MailCreateProps {
  isOpen: boolean;
  refetch: any;
  onClose: () => void;
}

export interface MailComposeType {
  schoolId?: string;
  subject: string;
  body: string;
  file?: {
    type: 'pdf' | 'jpeg' | 'jpg' | 'png' | 'doc' | 'docx';
    data: string;
  };
}

export const MailCreate: React.FC<MailCreateProps> = ({ isOpen, onClose, refetch }) => {
  const theme = useTheme();
  const [loadingCompose, setLoadingCompose] = useState(false);
  const { notifyError, notifySuccess } = useNotification();
  const { schools } = useSchools();

  const { values, forceChange } = useForm<MailComposeType>({
    schoolId: '',
    subject: '',
    body: '',
    file: null,
  });

  const handleSubmint = async () => {
    if (!values.schoolId || !values.subject || !values.body) {
      console.log(values);
      notifyError('Please fill all required fields!');
      return;
    } else {
      setLoadingCompose(true);
      try {
        const data = removeEmpty(values);
        console.log(data);
        const res = await composeMail(data);
        if (res.status === 200) {
          await refetch();
          notifySuccess(res.data.message);
          setLoadingCompose(false);
          onClose();
        } else {
          notifyError(res.data.message);
          setLoadingCompose(false);
        }
      } catch (err) {
        console.log(err);
        setLoadingCompose(false);
      }
    }
  };

  return (
    <WhiteModal
      open={isOpen}
      onClose={() => {
        if (!loadingCompose) {
          onClose();
        }
      }}
    >
      <WhiteModalContent component="form">
        <CustomText fontSize={1.4} bold={700} color={theme.color.darkerBlue}>
          COMPOSE
        </CustomText>
        <Divider />

        <Flex>
          <SchoolSelect
            value={values.schoolId}
            info="To *"
            onChange={(event: any, obj: { id: string }) => {
              if (obj?.id) {
                forceChange(obj.id, 'schoolId');
              } else {
                forceChange(null, 'schoolId');
              }
            }}
          />

          <AttachFile
            id="attachFile"
            info="Attach File"
            onChange={(obj: any) => {
              forceChange(obj, 'file');
            }}
          >
            <img src="/attach.png" alt="" />
            <CustomText margin="0 0 0 2%">Choose</CustomText>
          </AttachFile>
        </Flex>

        <InputField
          id="subject"
          info="Subject *"
          placeholder="Enter here ..."
          value={values.subject}
          onChange={(e: any) => {
            forceChange(e.target.value, 'subject');
          }}
        />

        <InputField
          id="message"
          info="Message *"
          placeholder="Enter here ..."
          multiline
          rows={6}
          value={values.body}
          onChange={(e: any) => {
            forceChange(e.target.value, 'body');
          }}
        />

        <Flex>
          <Flex>
            <CustomText>* Required field.</CustomText>
          </Flex>
          <Flex>
            <CustomButton
              loading={loadingCompose}
              content="SEND"
              type="button"
              width="100%"
              onClick={handleSubmint}
            />
          </Flex>
        </Flex>
      </WhiteModalContent>
    </WhiteModal>
  );
};
