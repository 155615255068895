import React from 'react';

export const PlusCircle: React.FC<{ color?: string; size?: number }> = ({ color, size }) => (
  <svg
    width={size ? size : '24'}
    height={size ? size : '24'}
    viewBox="0 0 24 24"
    fill={color ? color : 'white'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="plus-circle">
      <path
        id="Combined Shape"
        d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM11 16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H13V8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H11V16Z"
        fill={color ? color : 'white'}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <mask id="mask0_0_4279" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
        <path
          id="Combined Shape_2"
          d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM11 16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H13V8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H11V16Z"
          fill={color ? color : 'white'}
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_0_4279)">
        <g>
          <rect id="Rectangle" width="24" height="24" fill={color ? color : 'white'} />
        </g>
      </g>
    </g>
  </svg>
);
