import { Box, styled } from '@mui/material';

export const FlexE = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2%;
`;

export const ReplyContainer = styled(Box)`
  height: 50vh;
  flex: 0 0 45%;
  padding: 35px 0 0 44px;
  position: relative;

  @media (max-width: 768px) {
    padding: 0;
    margin: 2% 0;
  }
`;
export const Marker = styled(Box)<{ bgcolor?: boolean }>`
  height: 75%;
  width: 6px;
  border-radius: 0 6px 6px 0;
  background-color: ${({ theme, bgcolor }) => bgcolor && theme.palette.primary.light};
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const SupportMarker = styled(Marker)<{ active?: boolean }>`
  background-color: ${({ theme, active }) =>
    active ? theme.color.depositDarkGreen : theme.color.schollBillRed};
`;
