import { useTheme } from '@mui/material';
import React, { ChangeEvent } from 'react';

import { CustomButton, ElementaryDataContainer, ReplyContainer } from '../../common';
import { ShadowInputField } from '../../components/Inputs';
import { Loading } from '../../components/Loading';

export interface CreateReplyProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
  handleSubmit?: () => Promise<void>;
  handleExport?: () => Promise<void>;
  ticket?: any;
  exportExternal?: boolean | undefined;
}

export const CreateReply: React.FC<CreateReplyProps> = ({
  value,
  onChange,
  loading,
  handleSubmit,
  handleExport,
  ticket,
  exportExternal,
}) => {
  const theme = useTheme();
  if (!ticket) {
    return <Loading />;
  }
  return (
    <ReplyContainer component="form">
      <ShadowInputField
        padding="20px"
        multiline
        name="reply"
        rows={9}
        placeholder="Reply"
        value={value}
        onChange={onChange}
      />
      <ElementaryDataContainer justifyContent="flex-end" gap="20px" margin="4% 0">
        {ticket && !ticket.exportExternal && (
          <CustomButton
            content="Export to Pupil Wallet"
            invert
            bgcolor={theme.color.buttonGrey}
            textcolor={theme.palette.common.black}
            loading={loading}
            onClick={handleExport}
          />
        )}
        <CustomButton
          loading={loading}
          content="Send Message"
          width="24%"
          type="submit"
          onClick={handleSubmit}
        />
      </ElementaryDataContainer>
    </ReplyContainer>
  );
};
