import React from 'react';
import { styled, Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from '../svg/Arrow';
import { CustomText } from '../common';

export const BackTitleContainer = styled(Box)`
  width: 100%;
  display: flex;
  padding: 20px 0;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  border-top: 2px solid ${({ theme }) => theme.color.borderGrey};
`;

export const BackTitle: React.FC<{ title: string; onClick: () => void }> = ({ title, onClick }) => {
  return (
    <BackTitleContainer>
      <IconButton onClick={onClick}>
        <ArrowLeft color="black" size={30} />
      </IconButton>
      <CustomText bold={700} fontSize={1.7}>
        {title}
      </CustomText>
    </BackTitleContainer>
  );
};
