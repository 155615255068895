import { WhiteContainer, CustomText, Flex, CustomButton } from '../../common';
import { AddEditSchoolContainer, AddEditSchoolContent } from './Schools.style';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { Divider, MenuItem, useTheme } from '@mui/material';
import { useForm } from '../../hooks/useForm';
import { SelectField, InputField, PhoneNumberInput, ColorPicker } from '../../components/Inputs';
import { SmallCircle } from '../../components/RouteInfo/RouteInfo.style';
import { Wallet } from '../../svg/Wallet';
import { BackTitle } from '../../components/BackTitle';
import { SchoolAddType } from '../../types';
import { ImageInput } from './ImageInput';
import { isEmpty } from '../../helpers';
import { useNotification } from '../../hooks/useNotification';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { AutocompleteInput } from '../../components/Inputs/AutocompleteInput';
import { countries } from '../../constants/countries';

export const EditSchool: React.FC<{
  school: SchoolAddType;
  setEdit: any;
  schoolEdit: any;
}> = ({ school, setEdit, schoolEdit }) => {
  const theme = useTheme();
  const { notifyError } = useNotification();
  const typeOptions: string[] = ['university', 'primary', 'secondary'];
  const { values, onChange, forceChange, setValues } = useForm<SchoolAddType>({
    schoolId: school.uid,
    name: school?.name || '',
    type: school?.type || '',
    phoneNumber: school?.phoneNumber || '',
    founded: school?.founded || '',
    website: school?.website || '',
    hexColor: school?.hexColor || '',
    address: school?.address || '',
    image: {
      type: '',
      data: '',
    },
    bank: school?.bank || {
      name: '',
      country: '',
      accountNumber: '',
      ibanNumber: '',
      swiftCode: '',
    },
    squareData: {
      appId: school?.squareData.appId,
      locationId: school?.squareData.locationId,
      accessToken: school?.squareData.accessToken,
    },
  });

  const handleSubmit = () => {
    if (isEmpty(values) || isEmpty(values.squareData) || isEmpty(values.bank)) {
      notifyError('Please fill in all fields');
      return;
    } else if (!isValidPhoneNumber(values.phoneNumber)) {
      notifyError('Please provide valid phone number');
      return;
    }
    const data: any = values;
    console.log(data);
    if (isEmpty(values?.image)) {
      delete data.image;
    }
    console.log(data);
    schoolEdit.mutate(data);
  };

  return (
    <>
      <RouteInfo />
      <AddEditSchoolContainer>
        <Flex noFlex>
          <BackTitle title={'Edit School'} onClick={() => setEdit(false)} />
        </Flex>
        <WhiteContainer padding="0px">
          <AddEditSchoolContent>
            <Flex column p={20} fullHeight>
              <Flex flex={2} column fullHeight>
                <Flex noCenter>
                  <ImageInput
                    hexColor={values.hexColor}
                    schoolImage={school?.imageUrl}
                    handleChange={(data: string) => forceChange(data, 'image')}
                  />
                  <Flex flexEnd column fullHeight>
                    <InputField
                      type="text"
                      name="email"
                      info="Email"
                      padding="4px 20px"
                      placeholder="Email"
                      onChange={(e: any) => onChange(e)}
                      value={school.email}
                      disabled
                    />

                    <Flex>
                      <PhoneNumberInput
                        type="text"
                        name="phoneNumber"
                        info="Phone"
                        padding="4px 20px"
                        placeholder="Enter here..."
                        onChange={(value: string) => forceChange(value, 'phoneNumber')}
                        value={values.phoneNumber}
                      />
                      <InputField
                        type="number"
                        name="founded"
                        info="Founded"
                        padding="4px 20px"
                        placeholder="Enter here..."
                        onChange={(e: any) => onChange(e)}
                        value={values.founded}
                      />
                    </Flex>
                    <InputField
                      type="text"
                      name="website"
                      info="Website"
                      padding="4px 20px"
                      placeholder="Enter here..."
                      onChange={(e: any) => onChange(e)}
                      value={values.website}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex>
                <Flex>
                  <InputField
                    type="text"
                    name="name"
                    info="Name"
                    padding="4px 20px"
                    placeholder="School Name"
                    onChange={(e: any) => onChange(e)}
                    value={values.name}
                  />
                </Flex>
                <Flex>
                  <Flex>
                    <InputField
                      type="text"
                      name="squareData.appId"
                      info="Square Aplication ID"
                      padding="4px 20px"
                      placeholder="Enter here..."
                      value={values.squareData.appId}
                      onChange={(e: any) =>
                        setValues((prev: any) => ({
                          ...prev,
                          squareData: {
                            ...prev.squareData,
                            appId: e.target.value,
                          },
                        }))
                      }
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Flex>
                <Flex>
                  <Flex>
                    <SelectField
                      type="text"
                      name="type"
                      info="School Type"
                      padding="4px 20px"
                      placeholder="Select type"
                      onChange={(e: any) => onChange(e)}
                      value={values.type}
                    >
                      {typeOptions.map((type: string) => (
                        <MenuItem value={type}>{type.toLocaleUpperCase()}</MenuItem>
                      ))}
                    </SelectField>
                  </Flex>
                  <ColorPicker
                    type="text"
                    name="hexColor"
                    info="School Color"
                    padding="4px 20px"
                    placeholder="Pick Color"
                    onChange={(e: any) => forceChange(e.target.value, 'hexColor')}
                    value={values.hexColor}
                  />
                </Flex>
                <Flex>
                  <InputField
                    type="text"
                    name="locationId"
                    info="Sqare Location ID"
                    padding="4px 20px"
                    placeholder="Enter here..."
                    value={values.squareData.locationId}
                    onChange={(e: any) =>
                      setValues((prev: any) => ({
                        ...prev,
                        squareData: {
                          ...prev.squareData,
                          locationId: e.target.value,
                        },
                      }))
                    }
                  />
                </Flex>
              </Flex>
              <Flex>
                <Flex>
                  <Flex>
                    <InputField
                      type="text"
                      name="address"
                      info="Address"
                      padding="4px 20px"
                      placeholder="Enter here..."
                      onChange={(e: any) => onChange(e)}
                      value={values.address}
                    />
                  </Flex>
                </Flex>
                <Flex>
                  <InputField
                    type="text"
                    name="accessToken"
                    info="Access Token"
                    padding="4px 20px"
                    placeholder="Enter here..."
                    value={values.squareData.accessToken}
                    onChange={(e: any) =>
                      setValues((prev: any) => ({
                        ...prev,
                        squareData: {
                          ...prev.squareData,
                          accessToken: e.target.value,
                        },
                      }))
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
            <Divider orientation="vertical" />
            <Flex column p={20} fullHeight>
              <Flex column flex={2} spaceBetween>
                <Flex gap={20}>
                  <SmallCircle size={80}>
                    <Wallet />
                  </SmallCircle>
                  <CustomText bold={700} color={theme.color.darkerBlue} fontSize={1.4}>
                    Payout Information
                  </CustomText>
                </Flex>

                <AutocompleteInput
                  name="bank.country"
                  info="Country"
                  autoHighlight
                  placeholder="Select"
                  value={values.bank.country}
                  options={countries}
                  onChange={(e: any, obj: any) => {
                    setValues((prev: any) => ({
                      ...prev,
                      bank: {
                        ...prev.bank,
                        country: obj.name,
                      },
                    }));
                  }}
                />
              </Flex>
              <Flex>
                <InputField
                  type="text"
                  name="bank.name"
                  info="Bank Name"
                  padding="4px 20px"
                  placeholder="Enter here..."
                  value={values.bank.name}
                  onChange={(e: any) =>
                    setValues((prev: any) => ({
                      ...prev,
                      bank: {
                        ...prev.bank,
                        name: e.target.value,
                      },
                    }))
                  }
                />
              </Flex>
              <Flex>
                <InputField
                  type="text"
                  name="bank.accountNumber"
                  info="Account Number"
                  padding="4px 20px"
                  placeholder="0000000000"
                  value={values.bank.accountNumber}
                  onChange={(e: any) =>
                    setValues((prev: any) => ({
                      ...prev,
                      bank: {
                        ...prev.bank,
                        accountNumber: e.target.value,
                      },
                    }))
                  }
                />
              </Flex>
              <Flex>
                <InputField
                  type="text"
                  name="bank.swiftCode"
                  info="Swift Code"
                  padding="4px 20px"
                  placeholder="0000000000"
                  value={values.bank.swiftCode}
                  onChange={(e: any) =>
                    setValues((prev: any) => ({
                      ...prev,
                      bank: {
                        ...prev.bank,
                        swiftCode: e.target.value,
                      },
                    }))
                  }
                />
                <InputField
                  type="text"
                  name="bank.ibanNumber"
                  info="IBAN Number"
                  padding="4px 20px"
                  placeholder="0000000000"
                  onChange={(e: any) =>
                    setValues((prev: any) => ({
                      ...prev,
                      bank: {
                        ...prev.bank,
                        ibanNumber: e.target.value,
                      },
                    }))
                  }
                  value={values.bank.ibanNumber}
                />
              </Flex>
            </Flex>
          </AddEditSchoolContent>
        </WhiteContainer>
        <Flex p="20px 0" noFlex>
          <Flex gap={30} flexEnd>
            <Flex noFlex>
              <CustomButton width="200px" bgcolor={theme.color.grey} onClick={() => setEdit(false)}>
                <CustomText>Cancel</CustomText>
              </CustomButton>
            </Flex>
            <Flex noFlex>
              <CustomButton width="200px" loading={schoolEdit.isLoading} onClick={handleSubmit}>
                <CustomText color="white">Save</CustomText>
              </CustomButton>
            </Flex>
          </Flex>
        </Flex>
      </AddEditSchoolContainer>
    </>
  );
};
