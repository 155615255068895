import React, { useState } from 'react';
import { WhiteContainer, CustomText, Flex, TextContainer } from '../../common';
import { CardContainer, CardContent, CardBottom, CardImage } from './Card.style';
import { People } from '../../svg/People';
import { Arrow } from '../../svg/Arrow';
import { CircularProgress, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { SendInvoice } from '../../svg/SencInvoice';
import { Download } from '../../svg/Download';
import { SimpleSchool } from '../../pages/Schools/Schools';
import { base64toBlob } from '../../helpers';
import { getInvoicePdf } from '../../api';
import moment from 'moment';

export const Card: React.FC<SimpleSchool> = ({
  address,
  hexColor,
  id,
  image,
  name,
  usersCount,
}) => {
  const navigate = useNavigate();
  return (
    <WhiteContainer padding="0px">
      <CardContainer>
        <CardContent>
          <CardImage image={image} color={hexColor} />
          <TextContainer>
            <CustomText bold={700} fontSize={1.1}>
              {name}
            </CustomText>
            <CustomText>{address}</CustomText>
          </TextContainer>
        </CardContent>
        <CardBottom>
          <Flex>
            <People />
            <CustomText bold={700}>{usersCount}</CustomText>
          </Flex>
          <IconButton size="large" onClick={() => navigate(`${ROUTES.SCHOOLPROFILE}/${id}`)}>
            <Arrow />
          </IconButton>
        </CardBottom>
      </CardContainer>
    </WhiteContainer>
  );
};

export const InvoiceCard: React.FC<{
  date: number;
  description: string;
  id: string;
  school: {
    hexColor: string;
    id: string;
    image: string;
    name: string;
  };
  totalItemsPrice: number;
  vatPercentage: number;
}> = ({ date, description, id, school, totalItemsPrice, vatPercentage }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handlePdfDownload = async () => {
    setLoading(true);
    const res = await getInvoicePdf(id);
    const blob = base64toBlob(res.data.data);
    const url = URL.createObjectURL(blob);
    const downloadLink: any = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = `${school.name}_${moment.unix(date).format('DD/MM/YYYY')}`;
    downloadLink.click();
    setLoading(false);
  };

  return (
    <WhiteContainer padding="0px">
      <CardContainer>
        <CardContent onClick={() => navigate('/invoices/details/' + id)}>
          <CardImage image={school.image} color={school.hexColor} size={50} />
          <TextContainer>
            <CustomText bold={700} fontSize={1.1}>
              {school.name}
            </CustomText>
            <CustomText bold={700} fontSize={1.1}>
              £{totalItemsPrice / 100}
            </CustomText>
          </TextContainer>
        </CardContent>
        <CardBottom samePadding>
          <Flex spaceBetween>
            <IconButton size="large">
              <SendInvoice />
            </IconButton>
            <IconButton disabled={loading} size="large" onClick={handlePdfDownload}>
              {!loading ? <Download /> : <CircularProgress size={20} />}
            </IconButton>
          </Flex>
        </CardBottom>
      </CardContainer>
    </WhiteContainer>
  );
};
