import React from 'react';
import { Modal, styled, Box, useTheme, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomText, WhiteContainer } from '../../common';

export interface FilterPropTypes {
  children?: any;
  open: boolean;
  handleClose: () => void;
}

export const ModalContainer = styled(WhiteContainer)<{
  width?: number;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: ${({ width }) => (width ? width : 40)}%;
  width: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: scroll;
`;
export const ModalTitle = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGrey};
  position: sticky;
  z-index: 10;
  background-color: ${({ theme }) => theme.palette.common.white};
  top: 0;
`;

export const ModalContent = styled(Box)`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FilterModal: React.FC<FilterPropTypes> = ({ children, open, handleClose }) => {
  const theme = useTheme();
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContainer>
        <ModalTitle>
          <CustomText color={theme.color.darkerBlue} bold={700} fontSize={1.2}>
            FILTERS
          </CustomText>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </ModalTitle>
        <ModalContent>{children}</ModalContent>
      </ModalContainer>
    </Modal>
  );
};
