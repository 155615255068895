import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, MessagesAndFormContainer, MessagesList } from '../../common';

import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../constants';
import { decodeUrl } from '../../helpers';
import { useNotification } from '../../hooks/useNotification';
import { useTicket } from '../../hooks/useTicket';
import { CreateReply } from './CreateReply';
import { ExternalSupportMessages } from './ExternalSupportMessages';
import { ExternalSupportTicketDetail } from './ExternalSupportTicketDetail';

export const ExternalSupportTicket = () => {
  const { notifySuccess, notifyError } = useNotification();
  const [replyText, setReplyText] = useState<string>('');
  const [loadingResolve, setLoadingResolve] = useState(false);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const id = decodeUrl(pathname);
  const { ticket, reply, ticketResolve } = useTicket(id);
  const navigate = useNavigate();

  const back = () => {
    navigate(ROUTES.EXTERNAL_SUPPORT);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await reply(replyText);
      if (!res?.data.error) {
        notifySuccess('Message sent!');
        setLoading(false);
        setReplyText('');
      } else {
        notifyError(res?.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReplyText(event.target.value);
  };

  const handleResolve = async () => {
    setLoadingResolve(true);
    await ticketResolve.mutateAsync({ ticketId: ticket?.id, enabled: !ticket?.resolved });
    setLoadingResolve(false);
  };

  return (
    <>
      <RouteInfo
        banner="External Support"
        description="Manage internal support tickets"
        smImage="/internal-support-sm.png"
        redirect={back}
        noLastRoute
      />
      <Flex column>
        <ExternalSupportTicketDetail onClick={() => navigate(-1)} />
        <MessagesAndFormContainer>
          <MessagesList>
            {ticket?.messages.map(
              (
                message: {
                  body: string;
                  date: number;
                  from: { photo: string; name: string; email: string };
                  id: string;
                  isFromExternalSupport: boolean;
                  subject: string;
                },
                index: number,
              ) => (
                <ExternalSupportMessages
                  resolved={ticket.resolved}
                  exportExternal={ticket?.exportExternal}
                  main={index === 0}
                  resolve={handleResolve}
                  resolveLoading={loadingResolve}
                  {...message}
                />
              ),
            )}
          </MessagesList>

          {!ticket?.resolved && (
            <CreateReply
              value={replyText}
              onChange={onChange}
              handleSubmit={handleSubmit}
              loading={loading}
              ticket={ticket}
            />
          )}
        </MessagesAndFormContainer>
      </Flex>
    </>
  );
};
