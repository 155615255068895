import { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createInvoice, getInvoices } from '../api';
import { useNotification } from './useNotification';

export const useInvoices = (filter: any) => {
  const { notify, notifyError } = useNotification();
  const [invoices, setInvoices] = useState<any>([]);
  const { data, isLoading, refetch } = useQuery(['invoices', filter], () => getInvoices(filter));

  const mutationAdd = useMutation(createInvoice, {
    onSuccess: () => {
      refetch();
      notify({ message: 'Invoice created successfuly!' });
    },
    onError: (e: any) => {
      notifyError('Please fill all details!');
    },
  });

  useEffect(() => {
    if (data) {
      setInvoices(data.data.data);
    }
  }, [data]);

  return {
    invoices,
    isLoading,
    mutationAdd,
  };
};
