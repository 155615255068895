import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StoreType {
  notification: any;
  name: string;
  search: string;
  selectedGroup: any;
  externalSupportTicket: any;
  mailItem: any;
  type: string;
}

const initialState: StoreType = {
  name: '',
  search: '',
  selectedGroup: null,
  externalSupportTicket: null,
  mailItem: null,
  notification: null,
  type: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setNotification: (state: any, action: PayloadAction<any>) => {
      state.notification = action.payload;
    },

    setExternalSupportTicket: (state: any, action: PayloadAction<any>) => {
      state.externalSupportTicket = action.payload;
    },
    setMailItem: (state: any, action: PayloadAction<any>) => {
      state.mailItem = action.payload;
    },
  },
});

export const { setExternalSupportTicket, setMailItem, setNotification } = userSlice.actions;
export default userSlice.reducer;
