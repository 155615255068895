import React from 'react';

export const FullPlusCircle: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12ZM12 17.25C12.6234 17.25 13.125 16.7484 13.125 16.125V13.125H16.125C16.7484 13.125 17.25 12.6234 17.25 12C17.25 11.3766 16.7484 10.875 16.125 10.875H13.125V7.875C13.125 7.25156 12.6234 6.75 12 6.75C11.3766 6.75 10.875 7.25156 10.875 7.875V10.875H7.875C7.25156 10.875 6.75 11.3766 6.75 12C6.75 12.6234 7.25156 13.125 7.875 13.125H10.875V16.125C10.875 16.7484 11.3766 17.25 12 17.25Z"
      fill="white"
    />
  </svg>
);
