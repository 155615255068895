import React, { useState } from 'react';
import { BackTitle } from '../../components/BackTitle';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { ROUTES } from '../../constants';
import { CustomButton, CustomText, Flex, WhiteContainer } from '../../common';
import {
  InvoiceItemDisplay,
  ItemDisplayHeader,
} from './InvoiceDetailsContainers/InvoiceItemDisplay';
import { useLocation, useNavigate } from 'react-router-dom';
import { base64toBlob, decodeUrl } from '../../helpers';
import { useInvoice } from '../../hooks/useInvoice';
import { CircularProgress, Divider, TextField, useTheme } from '@mui/material';
import moment from 'moment';
import { Download } from '../../svg/Download';
import { getInvoicePdf } from '../../api';

export const InvoiceDetails = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const id = decodeUrl(pathname);
  const { invoice, isLoading } = useInvoice(id);

  const handlePdfDownload = async () => {
    setLoading(true);
    const res = await getInvoicePdf(id);
    const blob = base64toBlob(res.data.data);
    const url = URL.createObjectURL(blob);
    const downloadLink: any = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = `${invoice?.school?.name}_${invoice?.number}`;
    downloadLink.click();
    setLoading(false);
  };

  return (
    <>
      <RouteInfo noLastRoute />
      <Flex p="0 20" fullHeight noFlex column>
        <BackTitle title="Invoice Details" onClick={() => navigate(-1)} />
        <WhiteContainer>
          <Flex column>
            {!isLoading ? (
              <>
                <Flex spaceBetween>
                  <CustomText color={theme.color.darkerBlue} bold={700} fontSize={1.1}>
                    {invoice.school?.name}
                  </CustomText>
                  <Flex flexEnd>
                    <Flex column alignEnd gap="0">
                      <CustomText>Invoice Number</CustomText>
                      <CustomText bold={700}>{invoice.number}</CustomText>
                    </Flex>
                    <CustomButton loading={loading} onClick={handlePdfDownload}>
                      <Download color="white" />
                    </CustomButton>
                  </Flex>
                </Flex>
                <Flex noCenter flex={2}>
                  <Flex column noCenter>
                    <Flex column gap="0" noCenter>
                      <CustomText>Date</CustomText>
                      <CustomText bold={700}>
                        {moment.unix(invoice.date).format('MMM DD, YYYY')}
                      </CustomText>
                    </Flex>
                    <Flex column gap="0" noCenter>
                      <CustomText>Address</CustomText>
                      <CustomText bold={700}>{invoice?.school?.address}</CustomText>
                    </Flex>
                    <Flex column gap="0" noCenter>
                      <CustomText>Email</CustomText>
                      <CustomText bold={700}>{invoice?.school?.email}</CustomText>
                    </Flex>
                  </Flex>
                  <Flex flex={3} column>
                    <ItemDisplayHeader />
                    {invoice.items?.map((item: any) => (
                      <InvoiceItemDisplay {...item} />
                    ))}
                    <WhiteContainer padding="0">
                      <TextField
                        type="text"
                        name="description"
                        multiline
                        rows={12}
                        value={invoice?.description}
                        sx={{
                          width: '100%',
                        }}
                      />
                    </WhiteContainer>
                  </Flex>
                </Flex>
                <Flex>
                  <Flex column gap="0" alignEnd flexEnd>
                    <Flex alignEnd flexEnd>
                      <CustomText>Total Price:</CustomText>
                      <CustomText bold={700}>£{invoice?.totalItemsPrice / 100}</CustomText>
                    </Flex>
                    <Flex alignEnd flexEnd>
                      <CustomText>VAT ({invoice?.vatPercentage}%):</CustomText>
                      <CustomText bold={700}>
                        £{(invoice?.totalItemsPrice * invoice?.vatPercentage) / 10000}
                      </CustomText>
                    </Flex>
                  </Flex>
                </Flex>
                <Divider flexItem />
                <Flex alignEnd flexEnd>
                  <CustomText>Total Amount: </CustomText>
                  <CustomText bold={700} color={theme.color.darkerBlue}>
                    £
                    {(invoice?.totalItemsPrice +
                      (invoice?.totalItemsPrice * invoice?.vatPercentage) / 100) /
                      100}
                  </CustomText>
                </Flex>
              </>
            ) : (
              <CircularProgress size={70} />
            )}
          </Flex>
        </WhiteContainer>
      </Flex>
    </>
  );
};
