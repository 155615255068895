import { Typography, capitalize } from '@mui/material';
import React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';

import { CustomButton } from '../../common';
import { decodeUrl, formatUrl, makeLink } from '../../helpers';
import { Links, links } from '../AdminConsole/AdminConsole.constants';
import { LinkPaths, RouteInfoContainer, TextContainer } from './RouteInfo.style';
import { RouteInfoDetails } from './RouteInfoDetails';

interface RouteInfoProps {
  smImage?: string;
  margin?: string;
  onClick?: any;
  banner?: string;
  description?: string;
  redirect?: () => void;
  image?: any;
  withButton?: boolean;
  buttonText?: string;
  lastRoute?: string;
  noLastRoute?: boolean;
}

export const RouteInfo: React.FC<RouteInfoProps> = ({
  smImage,
  margin,
  onClick,
  banner,
  description,
  redirect,
  image,
  withButton,
  buttonText,
  lastRoute,
  noLastRoute,
}) => {
  const { pathname } = useLocation();
  const link = links.find((item: Links) => {
    return item?.link && item.link.replace('/', '').includes(pathname.split('/')[1]);
  });
  const paths = makeLink(formatUrl(pathname)).filter((path) => path.length);

  return (
    <RouteInfoContainer margin={margin}>
      {link ? (
        <TextContainer onClick={redirect}>
          {pathname.length < 2 ? (
            <>
              <Typography variant="h5">Pupil Wallet Console</Typography>
            </>
          ) : (
            <RouteInfoDetails
              pageName={link?.banner || capitalize(decodeUrl(pathname))}
              description={!link?.noDesc ? link?.descriptionText : ''}
              smImage={smImage || link?.smImage}
              image={link?.image}
            />
          )}
        </TextContainer>
      ) : (
        <TextContainer onClick={redirect}>
          <Typography variant="h5">Pupil Wallet Console</Typography>
        </TextContainer>
      )}

      <TextContainer right="flex-end">
        <LinkPaths variant="h6">
          <Link to="/">Pupil wallet / Pupil Waller Console</Link>
          {paths.map((path, index) => {
            if (noLastRoute && index >= paths.length - 1) {
              return null;
            }
            if (lastRoute && index >= paths.length - 1) {
              return (
                <Link to={``} key={index}>
                  {' '}
                  / {lastRoute}
                </Link>
              );
            }
            // if (index >= paths.length - 1) {
            //   return (
            //     <Link to={path} key={index}>
            //       {formatUrl(path)}
            //     </Link>
            //   );
            // }
            return (
              <Link to={noLastRoute && index >= paths.length - 2 ? '' : `../${path}`} key={index}>
                {' '}
                / {formatUrl(path)}
              </Link>
            );
          })}
        </LinkPaths>
        {withButton && link?.btnOption && pathname.length > 2 && (
          <CustomButton content={buttonText || link.btnOption} width="27%" onClick={onClick} />
        )}
      </TextContainer>
    </RouteInfoContainer>
  );
};
