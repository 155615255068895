import { Box, styled } from '@mui/material';

export const TitleContainer = styled(Box)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGrey};
`;

export const TitleInnerContainer = styled(Box)`
  padding: 20px 10px 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
