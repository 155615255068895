import { Box, styled } from '@mui/material';

export const PageContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: theme.palette.grey[100],
}));
export const FormContainer = styled(Box)`
  width: 40%;
  gap: 3rem;
  display: flex;
  align-items: space-evenly;
  justify-content: space-evenly;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  z-index: 11;
`;

export const TopImageContainer = styled(Box)<{ src: string }>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 40vh;
  background-image: url(${({ src }) => src});
  background-position: bottom;
  background-size: cover;
  display: flex;
  padding: 20px;
`;
export const BottomImageContainer = styled(Box)<{ src: string }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 75vh;
  background-image: url(${({ src }) => src});
  background-position: top;
  background-size: cover;
`;
