import { styled, Button, Stack, CircularProgress } from '@mui/material';
import React, { ReactElement, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

type property = string | number;

const CustomButtonContainer = styled(Button)<{
  height?: property;
  width?: property;
  bgcolor?: string;
  invert?: boolean;
  margin?: string;
  textcolor?: string;
}>`
  height: ${({ height }) => height && height};
  width: ${({ width }) => width && width};
  padding: 10px;
  border-radius: 8px;
  color: ${({ theme, invert, textcolor }) =>
    textcolor ? textcolor : invert ? theme.color.lightBlue : theme.palette.common.white};
  background: ${({ bgcolor, theme }) => (bgcolor ? bgcolor : theme.color.darkerBlue)};
  box-shadow: ${({ invert }) => invert && '0px 4px 10px rgba(0, 0, 0, 0.15)'};
  margin: ${({ margin }) => margin || '0'};

  &:hover {
    background: ${({ bgcolor, theme }) => (bgcolor ? bgcolor : theme.color.darkerBlue)};
  }
`;

interface CustomButtonProps {
  content?: ReactElement | string;
  height?: property;
  width?: property;
  bgcolor?: string;
  invert?: boolean;
  onClick?: any;
  margin?: string;
  loading?: boolean;
  color?: string;
  textcolor?: string;
  [x: string]: any;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  content,
  height,
  width,
  onClick,
  bgcolor,
  invert,
  margin,
  loading,
  textcolor,
  children,
  type,
  ...rest
}) => (
  <CustomButtonContainer
    height={height}
    width={width}
    bgcolor={bgcolor}
    textcolor={textcolor}
    margin={margin}
    invert={invert}
    onClick={onClick}
    disabled={loading}
    type={type}
  >
    {loading ? 'loading...' : content}
    {!loading && children}
  </CustomButtonContainer>
);

export const FetchingNextPageButton = styled(Button)`
  background-color: #fff;
  width: 100%;
  margin-top: 16px;
  border-radius: 10px;
`;

export const FetchButton: React.FC<{ onView: () => void; isFetchingNextPage: boolean }> = ({
  onView,
  isFetchingNextPage,
}) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      onView?.();
    }
  }, [inView]);
  return (
    <FetchingNextPageButton disabled ref={ref}>
      {isFetchingNextPage ? (
        <Stack alignItems="center" direction="row">
          Loading more... <CircularProgress size={16} />
        </Stack>
      ) : (
        'No more items'
      )}
    </FetchingNextPageButton>
  );
};
