import { useState, ChangeEvent } from 'react';

export const useForm = <T>(state: any) => {
  const [values, setValues] = useState<T>(state);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target) {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    } else {
      console.log(event);
    }
  };

  const forceChange = (value: any, property: string) => {
    setValues({
      ...values,
      [property]: value,
    });
  };

  return {
    values,
    setValues,
    onChange,
    forceChange,
  };
};
