import { Box, useTheme } from '@mui/material';
import { format } from 'date-fns';
import moment from 'moment';
import React from 'react';
import { SmallCustomAvatar, CustomText, WhiteContainer, Flex } from '../../common';
import { MAIL_DATE, MAIL_TIME } from '../../constants';
import { SupportMarker } from '../ExternalSupportTicket/ExternalSupportTicket.style';

interface ExternalSupportItemInboxProps {
  name: string;
  email: string;
  subject: string;
  date: number;
  resolved: boolean;
  lastMessage: {
    date: number;
    from: {
      name: string;
      email: string;
      photo: string;
    };
    subject: string;
  };
  status: boolean;
  onClick: () => void;
}

export const ExternalSupportItemInbox: React.FC<ExternalSupportItemInboxProps> = ({
  name,
  email,
  subject,
  date,
  lastMessage,
  status,
  onClick,
  resolved,
}) => {
  const theme = useTheme();
  return (
    <WhiteContainer onClick={onClick}>
      <SupportMarker active={resolved} />
      <Flex flex={1}>
        <SmallCustomAvatar image={lastMessage.from.photo} />
        <Box>
          <CustomText bold={700}>{lastMessage.from.name}</CustomText>
          <CustomText fontSize={0.9} bold={300}>
            {lastMessage.from.email}
          </CustomText>
        </Box>
      </Flex>

      <Flex flex={1}>{lastMessage.subject}</Flex>
      <Flex flex={1}>{moment.unix(date).format('DD/MM/YYYY')}</Flex>
      <Flex flex={1}>{moment.unix(date).format('HH:MM')}</Flex>
      <Flex flex={1}>
        <CustomText color={resolved ? theme.color.depositDarkGreen : theme.color.schollBillRed}>
          {resolved ? 'Resolved' : 'Unresolved'}
        </CustomText>
      </Flex>
    </WhiteContainer>
  );
};
