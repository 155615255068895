import React from 'react';
import { CustomText, IconContainer, Flex, FlexRowPadding } from '../';
import { TitleContainer, TitleInnerContainer } from './ChartTitle.style';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { Arrow } from '../../svg/Arrow';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ChartTitleTypes {
  title: string;
  date?: number | null;
  school?: string;
}
interface InfoTitleProps {
  title: string;
  image: React.ReactNode;
  link: string;
}

export const ChatrTitle: React.FC<ChartTitleTypes> = ({ title, date, school }) => {
  return (
    <TitleContainer>
      <TitleInnerContainer>
        <div>
          <CustomText bold={700} fontSize={1.2}>
            {title}
          </CustomText>
          <CustomText bold={400} fontSize={0.9}></CustomText>
        </div>
      </TitleInnerContainer>
    </TitleContainer>
  );
};

export const InfoTitle: React.FC<InfoTitleProps> = ({ title, image, link }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <TitleContainer>
      <TitleInnerContainer>
        <Flex>
          <IconContainer size="55px" p="13px">
            {image}
          </IconContainer>
          <CustomText bold={700} fontSize={1.2}>
            {title}
          </CustomText>
        </Flex>
        <IconButton size="large" onClick={() => navigate(link)}>
          <Arrow color={theme.color.darkerBlue} />
        </IconButton>
      </TitleInnerContainer>
    </TitleContainer>
  );
};

export const InfoMain: React.FC<{ title: string; value: number | string; children: any }> = ({
  title,
  value,
  children,
}) => {
  const theme = useTheme();
  return (
    <FlexRowPadding>
      <div>
        <CustomText bold={600}>{title}</CustomText>
        <CustomText bold={700} fontSize={1.4} color={theme.color.darkerBlue}>
          {value}
        </CustomText>
      </div>
      <Flex column noCenter contectCenter>
        {children}
      </Flex>
    </FlexRowPadding>
  );
};
