import { FormControl, InputLabel, Tab, TextField } from '@mui/material';
import React from 'react';

import { FiltersContainer, TabsContainer } from '../../common';

interface ExternalSupportControlProps {}

export const ExternalSupportControl: React.FC<ExternalSupportControlProps> = ({}) => (
  <FiltersContainer>
    <TabsContainer width="100%"></TabsContainer>
  </FiltersContainer>
);
