import { InvoiceCard } from '../../components/Card/Card';
import { CardSkeletonContainer, SchoolsPageContainer } from '../Schools/Schools.style';
import { RouteInfo } from '../../components/RouteInfo/RouteInfo';
import { useInvoices } from '../../hooks/useInvoices';
import { FiltersContainer, TabsContainer } from '../../common';
import { useState } from 'react';
import { InvoiceFilter } from './InvoiceFilter';
import { Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';

export const OneSchoolInvoices = () => {
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const params: any = useParams();
  const id = params.id;
  const [filter, setFilter] = useState<{
    from: number | null;
    to: number | null;
    schoolId: string | null;
  }>({
    from: null,
    to: null,
    schoolId: id,
  });
  const { invoices, isLoading, mutationAdd } = useInvoices(filter);
  return (
    <>
      <RouteInfo />
      <FiltersContainer>
        <TabsContainer width="95%"></TabsContainer>
      </FiltersContainer>
      <SchoolsPageContainer>
        {isLoading &&
          [...Array(12)].map(() => (
            <CardSkeletonContainer>
              <Skeleton variant="rectangular" animation="wave" width="100%" height="100%" />
            </CardSkeletonContainer>
          ))}
        {invoices && invoices?.map((invoice: any) => <InvoiceCard {...invoice} key={invoice.id} />)}
        <InvoiceFilter
          open={filterModalOpen}
          onSave={(value) => {
            setFilter(value);
            setFilterModalOpen(false);
          }}
          handleClose={() => setFilterModalOpen(false)}
        />
      </SchoolsPageContainer>
    </>
  );
};
