import React from 'react';

export const Headset: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="57" height="69" viewBox="0 0 57 69" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.625 0.187531C11.6875 1.15628 0.375 14.25 0.375 29.2188V50.125C0.375 55.3125 4.5625 59.5 9.75 59.5H12.875C16.3125 59.5 19.125 56.6875 19.125 53.25V40.75C19.125 37.3125 16.3125 34.5 12.875 34.5H6.625V29.1563C6.625 17.1563 15.875 6.71878 27.8438 6.37503C40.25 6.03128 50.375 15.9375 50.375 28.25V34.5H44.125C40.6875 34.5 37.875 37.3125 37.875 40.75V53.25C37.875 56.6875 40.6875 59.5 44.125 59.5H50.375V62.625H31.625C29.9062 62.625 28.5 64.0313 28.5 65.75C28.5 67.4688 29.9062 68.875 31.625 68.875H47.25C52.4375 68.875 56.625 64.6875 56.625 59.5V28.25C56.625 12.0938 43 -0.874969 26.625 0.187531Z"
      fill={color ? color : '#0C84FC'}
    />
  </svg>
);
