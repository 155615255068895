export enum ROUTES {
  LOGIN = '/login',
  REGISTER = '/register',
  DASHBOARD = '/',
  SCHOOLS = '/schools',
  SCHOOLPROFILE = '/schools',
  EXTERNAL_SUPPORT = '/external-support',
  EXTERNAL_SUPPORT_TICKET = '/external-support/ticket',
  INVOICES = '/invoices',
  INVOICEDETAILS = '/invoices/details/:id',
  ADDINVOICE = '/invoices/add-invoice',
  ADDSCHOOL = '/add-school',
  ADMIN = '/',
  FAQ = '/faq',
  OVERVIEW = '/overview',
  ANALYTICS = '/analytics',
  MAIL = '/mail',
  MAIL_PAGE = '/mail-item',
  PERMISIONS = '/permisions',
}

export const DATE_FORMAT = 'LLLL dd, yyyy';
export const MAIL_DATE = 'MM/dd/yyyy';
export const MAIL_TIME = 'HH:mm';

export const SUPPORT_DATE = `MM.dd.yyyy. ${MAIL_TIME}`;
